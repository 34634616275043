export const BoxScoresTable = ({boxScores}) => {
  return (
    <div className="flex flex-col w-full bg-white p-4 drop-shadow-sm">
      <table>
        <thead>
          <tr className="text-center">
            <th></th>
            <th>1</th>
            <th>2</th>
            <th>3</th>
            <th>4</th>
            <th>T</th>
          </tr>
        </thead>
        <tbody>
          <tr className="text-center">
            <td>{boxScores.away_team_abb}</td>
            <td>{boxScores.score_away_period_1}</td>
            <td>{boxScores.score_away_period_2}</td>
            <td>{boxScores.score_away_period_3}</td>
            <td>{boxScores.score_away_period_4}</td>
            <td>{boxScores.score_away_total}</td>
          </tr>

          <tr className="text-center">
            <td>{boxScores.home_team_abb}</td>
            <td>{boxScores.score_home_period_1}</td>
            <td>{boxScores.score_home_period_2}</td>
            <td>{boxScores.score_home_period_3}</td>
            <td>{boxScores.score_home_period_4}</td>
            <td>{boxScores.score_home_total}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
