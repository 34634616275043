import React, {useEffect} from 'react';
import {Link, Navigate, useNavigate} from 'react-router-dom';
import {icons} from '../../assets';

export const PaymentCancel = () => {
  return (
    <div className="Form flex flex-1 h-screen flex-col items-center justify-center bg-neutral-50">
      <div className="w-full h-1/3 mt-10">
        <div className="LogoDark flex justify-center items-center bg-black rounded-full mb-6 mx-auto">
          <img
            className="IconWhiteWithColor w-16 h-16"
            src={icons.logo}
            alt="App logo"
            loading="lazy"
          />
        </div>
        <h1 className="StartYourCryptoIn text-center text-neutral-900 text-2xl font-bold">
          Payment is Canceled
        </h1>
      </div>
    </div>
  );
};
