// import React, { useEffect, useState } from 'react';
// import { icons } from '../../../assets';
// import { Navbar } from '../../../components/Navbar';
// import { Sidebar } from '../../../components/Sidebar/Sidebar';
// import { useParams } from 'react-router-dom';
// import {
//   getTeam,
//   getTeamPlayers,
//   getTeamSchedule,
//   getTeamStats,
//   getTeamInjuries
// } from '../../../redux/actions/SportsActions';
// import { useDispatch } from 'react-redux';
// import { Loading } from '../../../components/LoadingComponent';
// import { TeamStats } from './TeamStatsComponent';
// import { Roster } from './RosterComponent';
// import { PastResults } from './PastResultsComponent';
// import { Schedule } from './ScheduleComponent';
// import { BottomBar } from '../../../components/Sidebar/BottomBar';
// import { updateUserInterests } from '../../../redux/actions/UserActions';

// export const TeamView = () => {
//   const { id: teamId } = useParams();
//   const [selectedTab, setSelectedTab] = useState('Past Results');
//   const [team, setTeam] = useState([]);
//   const [stats, setStats] = useState([]);
//   const [players, setPlayers] = useState([]);
//   const [schedules, setSchedules] = useState([]);
//   const [isStatsFetched, setIsStatsFetched] = useState(false);
//   const [isPlayersFetched, setIsPlayersFetched] = useState(false);
//   const [isScheduleFetched, setIsScheduleFetched] = useState(false);
//   const [injuries, setInjuries] = useState([]);

//   const dispatch = useDispatch();

//   useEffect(() => {
//     if (teamId) {
//       dispatch(getTeam(teamId)).then(response => {
//         setTeam(response);
//         setIsStatsFetched(false);
//         setIsPlayersFetched(false);
//         setIsScheduleFetched(false);
//       });
//     }
//   }, [dispatch, teamId]);

//   useEffect(() => {
//     if (
//       selectedTab === 'Past Results' &&
//       team.length > 0 &&
//       teamId &&
//       !isStatsFetched
//     ) {
//       dispatch(
//         getTeamStats(team[0].sport, team[0].league, team[0].team_name),
//       ).then(response => {
//         setStats(response);
//         setIsStatsFetched(true);
//       });
//     }
//   }, [dispatch, team, teamId, selectedTab, isStatsFetched]);

//   useEffect(() => {
//     if (
//       (selectedTab === 'Player Info' || selectedTab === 'Player AVG') &&
//       team.length > 0 &&
//       teamId &&
//       !isPlayersFetched
//     ) {
//       dispatch(getTeamPlayers(team[0].sport, team[0].league, teamId)).then(
//         response => {
//           setPlayers(response);
//           setIsPlayersFetched(true);
//         },
//       );
//     }
//   }, [dispatch, team, teamId, selectedTab, isPlayersFetched]);

//   useEffect(() => {
//     if (
//       selectedTab === 'Schedule' &&
//       team.length > 0 &&
//       teamId &&
//       !isScheduleFetched
//     ) {
//       dispatch(
//         getTeamSchedule(team[0].sport, team[0].league, team[0].team_name),
//       ).then(response => {
//         setSchedules(response);
//         setIsScheduleFetched(true);
//       });
//     }
//   }, [dispatch, team, teamId, selectedTab, isScheduleFetched]);

//   useEffect(() => {
//     if (teamId && team.length > 0) {
//       dispatch(getTeamInjuries(team[0].sport, team[0].league, teamId)).then(response => {
//         setInjuries(response);
//       });
//     }
//   }, [dispatch, teamId, team]);

//   const saveInterests = () => {
//     dispatch(updateUserInterests(team[0].team_name));
//   };

//   const renderContent = () => {
//     switch (selectedTab) {
//       case 'Player AVG':
//         return <TeamStats players={players} bgColor={team[0]?.dominantColor} />;
//       case 'Past Results':
//         return (
//           <PastResults
//             stats={stats}
//             team={team}
//             bgColor={team[0]?.dominantColor}
//           />
//         );
//       case 'Schedule':
//         return schedules ? (
//           <Schedule
//             schedules={schedules}
//             team={team}
//             bgColor={team[0]?.dominantColor}
//           />
//         ) : (
//           <div className="content h-full w-full flex items-center justify-center bg-white animate-bg">
//             <Loading />
//           </div>
//         );
//       case 'Player Info':
//         return players ? (
//           <Roster players={players} bgColor={team[0]?.dominantColor} />
//         ) : (
//           <div className="content h-full w-full flex items-center justify-center bg-white animate-bg">
//             <Loading />
//           </div>
//         );
//       default:
//         return null;
//     }
//   };

//   const getFormattedRecord = team => {
//     const winLossAllGames =
//       team?.records?.['Win/Loss']?.['Regular Season Games'];
//     const wins = winLossAllGames?.wins ?? '';
//     const losses = winLossAllGames?.losses ?? '';
//     return `${wins} - ${losses}`;
//   };

//   if (!team || team.length === 0) {
//     return (
//       <div className="content h-screen w-screen flex items-center justify-center bg-white animate-bg">
//         <Loading />
//       </div>
//     );
//   }

//   return (
//     <div className="w-screen h-screen bg-neutral-50 flex overflow-hidden">
//       <Sidebar />
//       {stats && stats.length > 0 ? (
//         <div className="flex flex-col h-full w-full overflow-y-auto">
//           <Navbar title="Sports" />
//           <div className="flex flex-col justify-around items-center flex-1">
//             <div className="flex flex-col w-11/12 lg:w-5/6">
//               <div
//                 className="flex w-full h-96 bg-sky-950 rounded-2xl p-4"
//                 style={{backgroundColor: team[0].dominantColor}}
//               >
//                 <div className="flex flex-col w-1/3 items-start gap-6">
//                   <p className="w-full px-3 py-6 text-lg sm:text-xl font-bold text-white font-Manrope leading-tight">
//                     {team[0].team_name}
//                   </p>
//                 </div>

//                 <div className="flex flex-col w-1/3 items-center gap-6 py-10">
//                   <div className="w-32 h-32 sm:w-48 sm:h-48">
//                     <img
//                       className="w-full h-full"
//                       src={team[0].logo}
//                       alt="Placeholder"
//                       loading="lazy"
//                     />
//                   </div>
//                   <p className="p-3.5 text-lg sm:text-xl font-normal text-white font-Manrope leading-tight">
//                     {team && team.length > 0
//                       ? getFormattedRecord(team[0])
//                       : 'Loading...'}{' '}
//                     • {stats[0]?.season_type}
//                   </p>
//                 </div>

//                 <div className="relative w-1/3">
//                   <button className="absolute top-0 right-0 p-2">
//                     <img className="text-white" src={icons.star} alt="img" loading="lazy"/>
//                   </button>
//                 </div>
//               </div>
//             </div>
//             <div className="Content flex flex-col flex-1 w-11/12 lg:w-5/6">
//               <div className="flex flex-col bg-white rounded-xl backdrop-blur-2xl">
//                 <div className="flex p-4">
//                   <div className="ContentHeader w-full flex flex-col bg-white rounded-xl backdrop-blur-2xl">
//                     <div className="flex lg:flex-row flex-col p-4">
//                       <div className="flex gap-5 items-center">
//                         <img
//                           className="w-14 h-14 sm:w-20 sm:h-20"
//                           src={team[0].logo}
//                           alt="Placeholder"
//                           loading="lazy"
//                         />
//                         <div className="flex flex-col justify-start">
//                           <div className="flex flex-row justify-between">
//                             <p className="text-base sm:text-lg font-bold underline">
//                               {team[0].team_name}
//                             </p>
//                             <p className="text-sm sm:text-base text-slate-600 ml-3">
//                               {stats[0]?.season_type} (
//                               {team && team.length > 0
//                                 ? getFormattedRecord(team[0])
//                                 : 'Loading...'}
//                               )
//                             </p>
//                           </div>
//                         </div>
//                       </div>
//                       <div className="lg:ml-auto justify-center p-4">
//                         {/*<button className="p-2 bg-white rounded-full border-2 border-sky-700 flex items-center">
//                           <img src={icons.play} alt="img" />
//                           <p className="text-base sm:text-lg">Live Game</p>
//                         </button>*/}
//                       </div>
//                     </div>
//                     <div className="flex p-4 gap-4">
//                       <button
//                         className={`p-1.5 text-sm sm:text-base cursor-pointer ${selectedTab === 'Past Results' ? 'text-black font-bold underline' : 'text-slate-500'}`}
//                         onClick={() => setSelectedTab('Past Results')}
//                       >
//                         Past Results
//                       </button>
//                       <button
//                         className={`p-1.5 text-sm sm:text-base cursor-pointer ${selectedTab === 'Player AVG' ? 'text-black font-bold underline' : 'text-slate-500'}`}
//                         onClick={() => setSelectedTab('Player AVG')}
//                       >
//                         Player AVG
//                       </button>
//                       <button
//                         className={`p-1.5 text-sm sm:text-base cursor-pointer ${selectedTab === 'Schedule' ? 'text-black font-bold underline' : 'text-slate-500'}`}
//                         onClick={() => setSelectedTab('Schedule')}
//                       >
//                         Schedule
//                       </button>
//                       <button
//                         className={`p-1.5 text-sm sm:text-base cursor-pointer ${selectedTab === 'Player Info' ? 'text-black font-bold underline' : 'text-slate-500'}`}
//                         onClick={() => setSelectedTab('Player Info')}
//                       >
//                         Player Info
//                       </button>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="flex flex-col bg-white backdrop-blur-2xl lg:mx-6 mb-24 sm:mb-0">
//                 {renderContent()}
//               </div>
//             </div>
//           </div>
//         </div>
//       ) : (
//         <div className="content h-screen w-screen flex items-center justify-center bg-white animate-bg">
//           <Loading />
//         </div>
//       )}
//       <BottomBar />
//     </div>
//   );
// };



// import React, { useEffect, useState } from 'react';
// import { icons } from '../../../assets';
// import { Navbar } from '../../../components/Navbar';
// import { Sidebar } from '../../../components/Sidebar/Sidebar';
// import { useParams } from 'react-router-dom';
// import {
//   getTeam,
//   getTeamPlayers,
//   getTeamSchedule,
//   getTeamStats,
//   getTeamInjuries
// } from '../../../redux/actions/SportsActions';
// import { useDispatch } from 'react-redux';
// import { Loading } from '../../../components/LoadingComponent';
// import { TeamStats } from './TeamStatsComponent';
// import { Roster } from './RosterComponent';
// import { PastResults } from './PastResultsComponent';
// import { Schedule } from './ScheduleComponent';
// import { BottomBar } from '../../../components/Sidebar/BottomBar';
// import { updateUserInterests } from '../../../redux/actions/UserActions';

// export const TeamView = () => {
//   const { id: teamId } = useParams();
//   const [selectedTab, setSelectedTab] = useState('Past Results');
//   const [team, setTeam] = useState([]);
//   const [stats, setStats] = useState([]);
//   const [players, setPlayers] = useState([]);
//   const [schedules, setSchedules] = useState([]);
//   const [isStatsFetched, setIsStatsFetched] = useState(false);
//   const [isPlayersFetched, setIsPlayersFetched] = useState(false);
//   const [isScheduleFetched, setIsScheduleFetched] = useState(false);
//   const [injuries, setInjuries] = useState([]);
//   const [isInjuriesFetched, setIsInjuriesFetched] = useState(false);
  
//   const dispatch = useDispatch();

//   useEffect(() => {
//     if (teamId) {
//       dispatch(getTeam(teamId)).then(response => {
//         setTeam(response);
//         setIsStatsFetched(false);
//         setIsPlayersFetched(false);
//         setIsScheduleFetched(false);
//       });
//     }
//   }, [dispatch, teamId]);

//   useEffect(() => {
//     if (
//       selectedTab === 'Past Results' &&
//       team.length > 0 &&
//       teamId &&
//       !isStatsFetched
//     ) {
//       dispatch(
//         getTeamStats(team[0].sport, team[0].league, team[0].team_name),
//       ).then(response => {
//         setStats(response);
//         setIsStatsFetched(true);
//       });
//     }
//   }, [dispatch, team, teamId, selectedTab, isStatsFetched]);

//   useEffect(() => {
//     if (
//       (selectedTab === 'Player Info' || selectedTab === 'Player AVG') &&
//       team.length > 0 &&
//       teamId &&
//       !isPlayersFetched
//     ) {
//       dispatch(getTeamPlayers(team[0].sport, team[0].league, teamId)).then(
//         response => {
//           setPlayers(response);
//           setIsPlayersFetched(true);
//         },
//       );
//     }
//   }, [dispatch, team, teamId, selectedTab, isPlayersFetched]);

//   useEffect(() => {
//     if (
//       selectedTab === 'Schedule' &&
//       team.length > 0 &&
//       teamId &&
//       !isScheduleFetched
//     ) {
//       dispatch(
//         getTeamSchedule(team[0].sport, team[0].league, team[0].team_name),
//       ).then(response => {
//         setSchedules(response);
//         setIsScheduleFetched(true);
//       });
//     }
//   }, [dispatch, team, teamId, selectedTab, isScheduleFetched]);

//   useEffect(() => {
//     if (teamId && team.length > 0 && !isInjuriesFetched) {
//       dispatch(getTeamInjuries(team[0].sport, team[0].league, teamId)).then(response => {
//         setInjuries(response);
//         setIsInjuriesFetched(true);
//       });
//     }
//   }, [dispatch, teamId, team, isInjuriesFetched]);
  

//   const saveInterests = () => {
//     dispatch(updateUserInterests(team[0].team_name));
//   };

//   const renderContent = () => {
//     switch (selectedTab) {
//       case 'Player AVG':
//         return <TeamStats players={players} bgColor={team[0]?.dominantColor} />;
//       case 'Past Results':
//         return (
//           <PastResults
//             stats={stats}
//             team={team}
//             bgColor={team[0]?.dominantColor}
//           />
//         );
//       case 'Schedule':
//         return schedules ? (
//           <Schedule
//             schedules={schedules}
//             team={team}
//             bgColor={team[0]?.dominantColor}
//           />
//         ) : (
//           <div className="content h-full w-full flex items-center justify-center bg-white animate-bg">
//             <Loading />
//           </div>
//         );
//       case 'Player Info':
//         return players ? (
//           <Roster players={players} bgColor={team[0]?.dominantColor} />
//         ) : (
//           <div className="content h-full w-full flex items-center justify-center bg-white animate-bg">
//             <Loading />
//           </div>
//         );
//         case 'Injuries':
//           return injuries ? (
//             <div className="flex flex-col bg-white backdrop-blur-2xl lg:mx-6 mb-24 sm:mb-0">
//               <div className="p-4">
//                 <h2 className="text-lg font-bold">Injuries</h2>
//                 <ul>
//                   {injuries.map(injury => (
//                     <li key={injury.player_id}>
//                       <p>{injury.player_name}: {injury.injury_details}</p>
//                     </li>
//                   ))}
//                 </ul>
//               </div>
//             </div>
//           ) : (
//             <div className="content h-full w-full flex items-center justify-center bg-white animate-bg">
//               <Loading />
//             </div>
//           );        
//       default:
//         return null;
//     }
//   };

//   const getFormattedRecord = team => {
//     const winLossAllGames =
//       team?.records?.['Win/Loss']?.['Regular Season Games'];
//     const wins = winLossAllGames?.wins ?? '';
//     const losses = winLossAllGames?.losses ?? '';
//     return `${wins} - ${losses}`;
//   };

//   if (!team || team.length === 0) {
//     return (
//       <div className="content h-screen w-screen flex items-center justify-center bg-white animate-bg">
//         <Loading />
//       </div>
//     );
//   }

//   return (
//     <div className="w-screen h-screen bg-neutral-50 flex overflow-hidden">
//       <Sidebar />
//       {stats && stats.length > 0 ? (
//         <div className="flex flex-col h-full w-full overflow-y-auto">
//           <Navbar title="Sports" />
//           <div className="flex flex-col justify-around items-center flex-1">
//             <div className="flex flex-col w-11/12 lg:w-5/6">
//               <div
//                 className="flex w-full h-96 bg-sky-950 rounded-2xl p-4"
//                 style={{backgroundColor: team[0].dominantColor}}
//               >
//                 <div className="flex flex-col w-1/3 items-start gap-6">
//                   <p className="w-full px-3 py-6 text-lg sm:text-xl font-bold text-white font-Manrope leading-tight">
//                     {team[0].team_name}
//                   </p>
//                 </div>

//                 <div className="flex flex-col w-1/3 items-center gap-6 py-10">
//                   <div className="w-32 h-32 sm:w-48 sm:h-48">
//                     <img
//                       className="w-full h-full"
//                       src={team[0].logo}
//                       alt="Placeholder"
//                       loading="lazy"
//                     />
//                   </div>
//                   <p className="p-3.5 text-lg sm:text-xl font-normal text-white font-Manrope leading-tight">
//                     {team && team.length > 0
//                       ? getFormattedRecord(team[0])
//                       : 'Loading...'}{' '}
//                     • {stats[0]?.season_type}
//                   </p>
//                 </div>

//                 <div className="relative w-1/3">
//                   <button className="absolute top-0 right-0 p-2">
//                     <img className="text-white" src={icons.star} alt="img" loading="lazy"/>
//                   </button>
//                 </div>
//               </div>
//             </div>
//             <div className="Content flex flex-col flex-1 w-11/12 lg:w-5/6">
//               <div className="flex flex-col bg-white rounded-xl backdrop-blur-2xl">
//                 <div className="flex p-4">
//                   <div className="ContentHeader w-full flex flex-col bg-white rounded-xl backdrop-blur-2xl">
//                     <div className="flex lg:flex-row flex-col p-4">
//                       <div className="flex gap-5 items-center">
//                         <img
//                           className="w-14 h-14 sm:w-20 sm:h-20"
//                           src={team[0].logo}
//                           alt="Placeholder"
//                           loading="lazy"
//                         />
//                         <div className="flex flex-col justify-start">
//                           <div className="flex flex-row justify-between">
//                             <p className="text-base sm:text-lg font-bold underline">
//                               {team[0].team_name}
//                             </p>
//                             <p className="text-sm sm:text-base text-slate-600 ml-3">
//                               {stats[0]?.season_type} (
//                               {team && team.length > 0
//                                 ? getFormattedRecord(team[0])
//                                 : 'Loading...'}
//                               )
//                             </p>
//                           </div>
//                         </div>
//                       </div>
//                       <div className="lg:ml-auto justify-center p-4">
//                         {/*<button className="p-2 bg-white rounded-full border-2 border-sky-700 flex items-center">
//                           <img src={icons.play} alt="img" />
//                           <p className="text-base sm:text-lg">Live Game</p>
//                         </button>*/}
//                       </div>
//                     </div>
//                     <div className="flex p-4 gap-4">
//                       <button
//                         className={`p-1.5 text-sm sm:text-base cursor-pointer ${selectedTab === 'Past Results' ? 'text-black font-bold underline' : 'text-slate-500'}`}
//                         onClick={() => setSelectedTab('Past Results')}
//                       >
//                         Past Results
//                       </button>
//                       <button
//                         className={`p-1.5 text-sm sm:text-base cursor-pointer ${selectedTab === 'Player AVG' ? 'text-black font-bold underline' : 'text-slate-500'}`}
//                         onClick={() => setSelectedTab('Player AVG')}
//                       >
//                         Player AVG
//                       </button>
//                       <button
//                         className={`p-1.5 text-sm sm:text-base cursor-pointer ${selectedTab === 'Schedule' ? 'text-black font-bold underline' : 'text-slate-500'}`}
//                         onClick={() => setSelectedTab('Schedule')}
//                       >
//                         Schedule
//                       </button>
//                       <button
//                         className={`p-1.5 text-sm sm:text-base cursor-pointer ${selectedTab === 'Player Info' ? 'text-black font-bold underline' : 'text-slate-500'}`}
//                         onClick={() => setSelectedTab('Player Info')}
//                       >
//                         Player Info
//                       </button>
//                       <button
//                         className={`p-1.5 text-sm sm:text-base cursor-pointer ${selectedTab === 'Injuries' ? 'text-black font-bold underline' : 'text-slate-500'}`}
//                         onClick={() => setSelectedTab('Injuries')}
//                       >
//                         Injuries
//                       </button>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="flex flex-col bg-white backdrop-blur-2xl lg:mx-6 mb-24 sm:mb-0">
//                 {renderContent()}
//               </div>
//             </div>
//           </div>
//         </div> 
//       ) : (
//         <div className="content h-screen w-screen flex items-center justify-center bg-white animate-bg">
//           <Loading />
//         </div>
//       )}
//       <BottomBar />
//     </div>
//   );
// };



// team view on staging last working one
// import React, { useEffect, useState } from 'react';
// import { icons } from '../../../assets';
// import { Navbar } from '../../../components/Navbar';
// import { Sidebar } from '../../../components/Sidebar/Sidebar';
// import { useParams } from 'react-router-dom';
// import {
//   getTeam,
//   getTeamPlayers,
//   getTeamSchedule,
//   getTeamStats,
// } from '../../../redux/actions/SportsActions';
// import { useDispatch, useSelector } from 'react-redux';
// import { Loading } from '../../../components/LoadingComponent';
// import { TeamStats } from './TeamStatsComponent';
// import { Roster } from './RosterComponent';
// import { PastResults } from './PastResultsComponent';
// import { Schedule } from './ScheduleComponent';
// import { updateUserInterests } from '../../../redux/actions/UserActions';
// // import useInjuries from '../../../hooks/useInjuries';

// export const TeamView = ({ darkMode }) => {
//   const { id: teamId } = useParams();
//   const [selectedTab, setSelectedTab] = useState('Past Results');
//   const [team, setTeam] = useState([]);
//   const [stats, setStats] = useState([]);
//   const [players, setPlayers] = useState([]);
//   const [schedules, setSchedules] = useState([]);
//   const [isStatsFetched, setIsStatsFetched] = useState(false);
//   const [isPlayersFetched, setIsPlayersFetched] = useState(false);
//   const [isScheduleFetched, setIsScheduleFetched] = useState(false);

//   const dispatch = useDispatch();

//   useEffect(() => {
//     if (teamId) {
//       dispatch(getTeam(teamId)).then(response => {
//         setTeam(response);
//         setIsStatsFetched(false);
//         setIsPlayersFetched(false);
//         setIsScheduleFetched(false);
//       });
//     }
//   }, [dispatch, teamId]);

//   useEffect(() => {
//     if (
//       selectedTab === 'Past Results' &&
//       team.length > 0 &&
//       teamId &&
//       !isStatsFetched
//     ) {
//       dispatch(
//         getTeamStats(team[0].sport, team[0].league, team[0].team_name),
//       ).then(response => {
//         setStats(response);
//         setIsStatsFetched(true);
//       });
//     }
//   }, [dispatch, team, teamId, selectedTab, isStatsFetched]);

//   useEffect(() => {
//     if (
//       (selectedTab === 'Player Info' || selectedTab === 'Player AVG') &&
//       team.length > 0 &&
//       teamId &&
//       !isPlayersFetched
//     ) {
//       dispatch(getTeamPlayers(team[0].sport, team[0].league, teamId)).then(
//         response => {
//           setPlayers(response);
//           setIsPlayersFetched(true);
//         },
//       );
//     }
//   }, [dispatch, team, teamId, selectedTab, isPlayersFetched]);

//   useEffect(() => {
//     if (
//       selectedTab === 'Schedule' &&
//       team.length > 0 &&
//       teamId &&
//       !isScheduleFetched
//     ) {
//       dispatch(
//         getTeamSchedule(team[0].sport, team[0].league, team[0].team_name),
//       ).then(response => {
//         setSchedules(response);
//         setIsScheduleFetched(true);
//       });
//     }
//   }, [dispatch, team, teamId, selectedTab, isScheduleFetched]);

//   // Fetch injuries data
//   // const { injuries, loading: injuriesLoading, error: injuriesError } = useInjuries(team[0]?.sport, teamId);
 
//   const saveInterests = () => {
//     dispatch(updateUserInterests(team[0].team_name));
//   };

//   const renderContent = () => {
//     switch (selectedTab) {
//       case 'Player AVG':
//         return <TeamStats players={players} bgColor={team[0]?.dominantColor} />;
//       case 'Past Results':
//         return (
//           <PastResults
//             stats={stats}
//             team={team}
//             bgColor={team[0]?.dominantColor}
//           />
//         );
//       case 'Schedule':
//         return schedules ? (
//           <Schedule
//             schedules={schedules}
//             team={team}
//             bgColor={team[0]?.dominantColor}
//           />
//         ) : (
//           <div className="content h-full w-full flex items-center justify-center bg-white animate-bg">
//             <Loading />
//           </div>
//         );
//       case 'Player Info':
//         return players ? (
//           <Roster players={players} bgColor={team[0]?.dominantColor} />
//         ) : (
//           <div className="content h-full w-full flex items-center justify-center bg-white animate-bg">
//             <Loading />
//           </div>
//         );
//       // case 'Injuries': // injuries tab
//       //   return (
//       //     <div>
//       //       {injuriesLoading ? (
//       //         <div>Loading...</div>
//       //       ) : injuriesError ? (
//       //         <div>Error loading injuries: {injuriesError.message}</div>
//       //       ) : injuries.length === 0 ? (
//       //         <div>No injuries reported.</div>
//       //       ) : (
//       //         <ul>
//       //           {injuries.map((injury) => (
//       //             <li key={injury.id}>
//       //               {injury.player_name} ({injury.position}) - {injury.injury_status} ({injury.injury_type})
//       //             </li>
//       //           ))}
//       //         </ul>
//       //       )}
//       //     </div>
//       //   );
//       default:
//         return null;
//     }
//   };

//   const getFormattedRecord = team => {
//     const winLossAllGames =
//       team?.records?.['Win/Loss']?.['Regular Season Games'];
//     const wins = winLossAllGames?.wins ?? '';
//     const losses = winLossAllGames?.losses ?? '';
//     return `${wins} - ${losses}`;
//   };

//   if (!team || team.length === 0) {
//     return (
//       <div className={`content h-screen w-screen flex items-center justify-center ${darkMode ? 'bg-neutral-900' : 'bg-white'} animate-bg`}>
//         <Loading />
//       </div>
//     );
//   }

//   return (
//     <div className={`w-screen h-screen ${darkMode ? 'bg-neutral-900' : 'bg-neutral-50'} flex overflow-hidden`}>
//       <Sidebar darkMode={darkMode} />
//       {stats && stats.length > 0 ? (
//         <div className="flex flex-col h-full w-full overflow-y-auto">
//           <div className={`sticky top-0 z-20 ${darkMode ? 'bg-black' : 'bg-white'}`}>
//             <Navbar title="" darkMode={darkMode} />
//           </div>
//           <div className="flex flex-col justify-around items-center flex-1">
//             <div className="flex flex-col w-11/12 lg:w-5/6">
//               <div
//                 className="flex w-full h-96 bg-sky-950 rounded-2xl p-4"
//                 style={{backgroundColor: team[0].dominantColor}}
//               >
//                 <div className="flex flex-col w-1/3 items-start gap-6">
//                   <p className="w-full px-3 py-6 text-lg sm:text-xl font-bold text-white font-Manrope leading-tight">
//                     {team[0].team_name}
//                   </p>
//                 </div>

//                 <div className="flex flex-col w-1/3 items-center gap-6 py-10">
//                   <div className="w-32 h-32 sm:w-48 sm:h-48">
//                     <img
//                       className="w-full h-full"
//                       src={team[0].logo}
//                       alt="Placeholder"
//                       loading="lazy"
//                     />
//                   </div>
//                   <p className="p-3.5 text-lg sm:text-xl font-normal text-white font-Manrope leading-tight">
//                     {team && team.length > 0
//                       ? getFormattedRecord(team[0])
//                       : 'Loading...'}{' '}
//                     • {stats[0]?.season_type}
//                   </p>
//                 </div>

//                 <div className="relative w-1/3">
//                   <button className="absolute top-0 right-0 p-2">
//                     <img className="text-white" src={icons.star} alt="img" loading="lazy"/>
//                   </button>
//                 </div>
//               </div>
//             </div>
//             <div className="Content flex flex-col flex-1 w-11/12 lg:w-5/6">
//               <div className={`flex flex-col ${darkMode ? 'bg-neutral-800' : 'bg-white'} rounded-xl backdrop-blur-2xl`}>
//                 <div className="flex p-4">
//                   <div className={`ContentHeader w-full flex flex-col ${darkMode ? 'bg-neutral-800' : 'bg-white'} rounded-xl backdrop-blur-2xl`}>
//                     <div className="flex lg:flex-row flex-col p-4">
//                       <div className="flex gap-5 items-center">
//                         <img
//                           className="w-14 h-14 sm:w-20 sm:h-20"
//                           src={team[0].logo}
//                           alt="Placeholder"
//                           loading="lazy"
//                         />
//                         <div className="flex flex-col justify-start">
//                           <div className="flex flex-row justify-between">
//                             <p className="text-base sm:text-lg font-bold underline">
//                               {team[0].team_name}
//                             </p>
//                             <p className="text-sm sm:text-base text-slate-600 ml-3">
//                               {stats[0]?.season_type} (
//                               {team && team.length > 0
//                                 ? getFormattedRecord(team[0])
//                                 : 'Loading...'}
//                               )
//                             </p>
//                           </div>
//                         </div>
//                       </div>
//                       <div className="lg:ml-auto justify-center p-4">
//                       </div>
//                     </div>
//                     <div className="flex p-4 gap-4">
//                       <button
//                         className={`p-1.5 text-sm sm:text-base cursor-pointer ${selectedTab === 'Past Results' ? 'text-black font-bold underline' : 'text-slate-500'}`}
//                         onClick={() => setSelectedTab('Past Results')}
//                       >
//                         Past Results
//                       </button>
//                       <button
//                         className={`p-1.5 text-sm sm:text-base cursor-pointer ${selectedTab === 'Player AVG' ? 'text-black font-bold underline' : 'text-slate-500'}`}
//                         onClick={() => setSelectedTab('Player AVG')}
//                       >
//                         Player AVG
//                       </button>
//                       <button
//                         className={`p-1.5 text-sm sm:text-base cursor-pointer ${selectedTab === 'Schedule' ? 'text-black font-bold underline' : 'text-slate-500'}`}
//                         onClick={() => setSelectedTab('Schedule')}
//                       >
//                         Schedule
//                       </button>
//                       <button
//                         className={`p-1.5 text-sm sm:text-base cursor-pointer ${selectedTab === 'Player Info' ? 'text-black font-bold underline' : 'text-slate-500'}`}
//                         onClick={() => setSelectedTab('Player Info')}
//                       >
//                         Player Info
//                       </button>
//                       {/* <button 
//                         className={`p-1.5 text-sm sm:text-base cursor-pointer ${selectedTab === 'Injuries' ? 'text-black font-bold underline' : 'text-slate-500'}`}
//                         onClick={() => setSelectedTab('Injuries')}
//                       >
//                         Injuries
//                       </button> */}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className={`flex flex-col ${darkMode ? 'bg-neutral-800' : 'bg-white'} backdrop-blur-2xl lg:mx-6 mb-24 sm:mb-0`}>
//                 {renderContent()}
//               </div>
//             </div>
//           </div>
//         </div> 
//       ) : (
//         <div className={`content h-screen w-screen flex items-center justify-center ${darkMode ? 'bg-neutral-900' : 'bg-white'} animate-bg`}>
//           <Loading />
//         </div>
//       )}
//       {/* <BottomBar /> */}
//     </div>
//   );
// };



import React, { useEffect, useState } from 'react';
import { icons } from '../../../assets';
import { Navbar } from '../../../components/Navbar';
import { Sidebar } from '../../../components/Sidebar/Sidebar';
import { useParams } from 'react-router-dom';
import {
  getTeam,
  getTeamPlayers,
  getTeamSchedule,
  getTeamStats,
} from '../../../redux/actions/SportsActions';
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from '../../../components/LoadingComponent';
import { TeamStats } from './TeamStatsComponent';
import { Roster } from './RosterComponent';
import { PastResults } from './PastResultsComponent';
import { Schedule } from './ScheduleComponent';
import { updateUserInterests } from '../../../redux/actions/UserActions';

export const TeamView = ({ darkMode }) => {
  const { id: teamId } = useParams();
  const [selectedTab, setSelectedTab] = useState('Past Results');
  const [team, setTeam] = useState([]);
  const [stats, setStats] = useState([]);
  const [players, setPlayers] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [isStatsFetched, setIsStatsFetched] = useState(false);
  const [isPlayersFetched, setIsPlayersFetched] = useState(false);
  const [isScheduleFetched, setIsScheduleFetched] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (teamId) {
      dispatch(getTeam(teamId)).then(response => {
        setTeam(response);
        setIsStatsFetched(false);
        setIsPlayersFetched(false);
        setIsScheduleFetched(false);
      });
    }
  }, [dispatch, teamId]);

  useEffect(() => {
    if (team.length > 0 && teamId && !isScheduleFetched) {
      dispatch(
        getTeamSchedule(team[0].sport, team[0].league, team[0].team_name),
      ).then(response => {
        setSchedules(response);
        setIsScheduleFetched(true);
      });
    }
  }, [dispatch, team, teamId, isScheduleFetched]);

  useEffect(() => {
    if (
      selectedTab === 'Past Results' &&
      team.length > 0 &&
      teamId &&
      !isStatsFetched
    ) {
      dispatch(
        getTeamStats(team[0].sport, team[0].league, team[0].team_name),
      ).then(response => {
        setStats(response);
        setIsStatsFetched(true);
      });
    }
  }, [dispatch, team, teamId, selectedTab, isStatsFetched]);

  useEffect(() => {
    if (
      (selectedTab === 'Player Info' || selectedTab === 'Player AVG') &&
      team.length > 0 &&
      teamId &&
      !isPlayersFetched
    ) {
      dispatch(getTeamPlayers(team[0].sport, team[0].league, teamId)).then(
        response => {
          setPlayers(response);
          setIsPlayersFetched(true);
        },
      );
    }
  }, [dispatch, team, teamId, selectedTab, isPlayersFetched]);

  const saveInterests = () => {
    dispatch(updateUserInterests(team[0].team_name));
  };

  const getFirstUpcomingGameDate = schedules => {
    if (!schedules || schedules.length === 0) return null;
    const upcomingGames = schedules.filter(game => {
      const gameDate = new Date(game.start_date);
      return gameDate > new Date();
    });
    
    return upcomingGames.length > 0 ? new Date(upcomingGames[0].start_date) : null;
  };

  const renderContent = () => {
    if (selectedTab === 'Past Results') {
      if (stats.length > 0) {
        return (
          <PastResults
            stats={stats}
            team={team}
            bgColor={team[0]?.dominantColor}
          />
        );
      } else {
        const firstGameDate = getFirstUpcomingGameDate(schedules);
        return (
          <div className="flex items-center justify-center h-full w-full">
            <p className="text-xl">
              No stats available. The season begins on{' '}
              {firstGameDate ? firstGameDate.toLocaleDateString() : 'N/A'}.
            </p>
          </div>
        );
      }
    }
    switch (selectedTab) {
      case 'Player AVG':
        return <TeamStats players={players} bgColor={team[0]?.dominantColor} />;
      case 'Schedule':
        return schedules ? (
          <Schedule
            schedules={schedules}
            team={team}
            bgColor={team[0]?.dominantColor}
          />
        ) : (
          <div className="content h-full w-full flex items-center justify-center bg-white animate-bg">
            <Loading />
          </div>
        );
      case 'Player Info':
        return players ? (
          <Roster players={players} bgColor={team[0]?.dominantColor} />
        ) : (
          <div className="content h-full w-full flex items-center justify-center bg-white animate-bg">
            <Loading />
          </div>
        );
      default:
        return null;
    }
  };

  const getFormattedRecord = team => {
    const winLossAllGames =
      team?.records?.['Win/Loss']?.['Regular Season Games'];
    const wins = winLossAllGames?.wins ?? '';
    const losses = winLossAllGames?.losses ?? '';
    return `${wins} - ${losses}`;
  };

  if (!team || team.length === 0) {
    return (
      <div className={`content h-screen w-screen flex items-center justify-center ${darkMode ? 'bg-neutral-900' : 'bg-white'} animate-bg`}>
        <Loading />
      </div>
    );
  }

  return (
    <div className={`w-screen h-screen ${darkMode ? 'bg-neutral-900' : 'bg-neutral-50'} flex overflow-hidden`}>
      <Sidebar darkMode={darkMode} />
      {(isStatsFetched && stats.length === 0 && schedules.length > 0) || (isStatsFetched && stats.length > 0) ? (
        <div className="flex flex-col h-full w-full overflow-y-auto">
          <div className={`sticky top-0 z-20 ${darkMode ? 'bg-black' : 'bg-white'}`}>
            <Navbar title="" darkMode={darkMode} />
          </div>
          <div className="flex flex-col justify-around items-center flex-1">
            <div className="flex flex-col w-11/12 lg:w-5/6">
              <div
                className="flex w-full h-96 bg-sky-950 rounded-2xl p-4"
                style={{backgroundColor: team[0].dominantColor}}
              >
                <div className="flex flex-col w-1/3 items-start gap-6">
                  <p className="w-full px-3 py-6 text-lg sm:text-xl font-bold text-white font-Manrope leading-tight">
                    {team[0].team_name}
                  </p>
                </div>

                <div className="flex flex-col w-1/3 items-center gap-6 py-10">
                  <div className="w-32 h-32 sm:w-48 sm:h-48">
                    <img
                      className="w-full h-full"
                      src={team[0].logo}
                      alt="Placeholder"
                      loading="lazy"
                    />
                  </div>
                  <p className="p-3.5 text-lg sm:text-xl font-normal text-white font-Manrope leading-tight">
                    {team && team.length > 0
                      ? getFormattedRecord(team[0])
                      : 'Loading...'}{' '}
                    • {stats[0]?.season_type}
                  </p>
                </div>

                <div className="relative w-1/3">
                  <button className="absolute top-0 right-0 p-2">
                    <img className="text-white" src={icons.star} alt="img" loading="lazy"/>
                  </button>
                </div>
              </div>
            </div>
            <div className="Content flex flex-col flex-1 w-11/12 lg:w-5/6">
              <div className={`flex flex-col ${darkMode ? 'bg-neutral-800' : 'bg-white'} rounded-xl backdrop-blur-2xl`}>
                <div className="flex p-4">
                  <div className={`ContentHeader w-full flex flex-col ${darkMode ? 'bg-neutral-800' : 'bg-white'} rounded-xl backdrop-blur-2xl`}>
                    <div className="flex lg:flex-row flex-col p-4">
                      <div className="flex gap-5 items-center">
                        <img
                          className="w-14 h-14 sm:w-20 sm:h-20"
                          src={team[0].logo}
                          alt="Placeholder"
                          loading="lazy"
                        />
                        <div className="flex flex-col justify-start">
                          <div className="flex flex-row justify-between">
                            <p className="text-base sm:text-lg font-bold underline">
                              {team[0].team_name}
                            </p>
                            <p className="text-sm sm:text-base text-slate-600 ml-3">
                              {stats[0]?.season_type} (
                              {team && team.length > 0
                                ? getFormattedRecord(team[0])
                                : 'Loading...'}
                              )
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="lg:ml-auto justify-center p-4">
                      </div>
                    </div>
                    <div className="flex p-4 gap-4">
                      <button
                        className={`p-1.5 text-sm sm:text-base cursor-pointer ${selectedTab === 'Past Results' ? 'text-black font-bold underline' : 'text-slate-500'}`}
                        onClick={() => setSelectedTab('Past Results')}
                      >
                        Past Results
                      </button>
                      <button
                        className={`p-1.5 text-sm sm:text-base cursor-pointer ${selectedTab === 'Player AVG' ? 'text-black font-bold underline' : 'text-slate-500'}`}
                        onClick={() => setSelectedTab('Player AVG')}
                      >
                        Player AVG
                      </button>
                      <button
                        className={`p-1.5 text-sm sm:text-base cursor-pointer ${selectedTab === 'Schedule' ? 'text-black font-bold underline' : 'text-slate-500'}`}
                        onClick={() => setSelectedTab('Schedule')}
                      >
                        Schedule
                      </button>
                      <button
                        className={`p-1.5 text-sm sm:text-base cursor-pointer ${selectedTab === 'Player Info' ? 'text-black font-bold underline' : 'text-slate-500'}`}
                        onClick={() => setSelectedTab('Player Info')}
                      >
                        Player Info
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`flex flex-col ${darkMode ? 'bg-neutral-800' : 'bg-white'} backdrop-blur-2xl lg:mx-6 mb-24 sm:mb-0`}>
                {renderContent()}
              </div>
            </div>
          </div>
        </div> 
      ) : (
        <div className={`content h-screen w-screen flex items-center justify-center ${darkMode ? 'bg-neutral-900' : 'bg-white'} animate-bg`}>
          <Loading />
        </div>
      )}
    </div>
  );
};
