import { Link } from 'react-router-dom';
import { Loading } from '../../components/LoadingComponent';
import { Navbar } from '../../components/Navbar';
import { Sidebar } from '../../components/Sidebar/Sidebar';
import {
  getMoneylineForTeam,
  getSpreadOrTotalGoalsForTeam,
  getTotalForTeam,
  getTotalRounds,
} from '../../utils/sportStats/oddsFunctions';
import { PropBetsTable } from './components/PropBetsTable';
import { PlayerBoxScores } from './components/PlayersBoxScores';
import { BoxScoresTable } from './components/BoxScoresTable';
import {
  formatFullDateWithOrdinalSuffix,
  formatGameDate,
  formatToLocaleTimeString,
} from '../../utils/dateUtils';
import { TrendingTicker } from '../../components/TrendingTicker';

const TeamLink = ({ team, logo, abbreviation, record, darkMode }) => (
  <Link to={`/sports/team/${team.id}`} className="flex flex-col items-center">
    <img className="w-9 h-9 mb-1 object-cover" src={logo} alt={team.team_name} loading="lazy"/>
    <span className={`text-xs font-semibold leading-tight mb-1 ${darkMode ? 'text-white' : 'text-black'}`}>
      {abbreviation}
    </span>
    <span className={`text-xs font-normal leading-tight ${darkMode ? 'text-neutral-400' : 'text-neutral-500'}`}>
      {record ? record : 'Loading...'}
    </span>
  </Link>
);

const GameInfo = ({ game, gameDate, gameTime, liveGame, darkMode }) => (
  <div className="flex flex-col items-center text-center w-1/2">
    {(game.is_live || game.status === 'completed') && liveGame ? (
      <div className="flex flex-row justify-around w-full">
        <h2 className={`text-xl font-bold ${darkMode ? 'text-white' : 'text-black'}`}>{liveGame.score_away_total}</h2>
        <span>-</span>
        <h2 className={`text-xl font-bold ${darkMode ? 'text-white' : 'text-black'}`}>{liveGame.score_home_total}</h2>
      </div>
    ) : (
      <span className={`text-xs font-medium leading-tight mb-2 ${darkMode ? 'text-white' : 'text-black'}`}>
        {gameDate}
        <br />
        {gameTime}
      </span>
    )}
  </div>
);

const GameRecord = (team) => {
  const winLossAllGames = team?.records?.['Win/Loss']?.['Regular Season Games'];
  if (winLossAllGames) {
    return `${winLossAllGames.wins} - ${winLossAllGames.losses}`;
  }

  const atsAllGames = team?.records?.['Over/Under']?.['Regular Season Games'];
  if (atsAllGames) {
    return `${atsAllGames.wins} - ${atsAllGames.losses}`;
  }

  return '';
};

export const PropBetsView = ({
  data,
  playersData,
  liveGame,
  isSubscribed,
  loading,
  setTeamSelection,
  handleUserAccessForData,
  darkMode,
}) => {
  const game = data?.gameScore;
  const gameOdds = data?.gameOdds;
  const homeTeam = data.home_team_info;
  const awayTeam = data.away_team_info;

  const homeMoneyline = gameOdds?.odds
    ? getMoneylineForTeam(gameOdds?.odds, gameOdds?.home_team)
    : null;
  const awayMoneyline = gameOdds?.odds
    ? getMoneylineForTeam(gameOdds?.odds, gameOdds?.away_team)
    : null;
  const matchingSpreads = gameOdds?.odds
    ? getSpreadOrTotalGoalsForTeam(
      gameOdds?.odds,
      gameOdds?.home_team,
      gameOdds?.away_team,
      gameOdds?.sport
    )
    : null;
  const homeTotal = gameOdds?.odds ? getTotalForTeam(gameOdds?.odds, 'under') : null;
  const awayTotal = gameOdds?.odds ? getTotalForTeam(gameOdds?.odds, 'over') : null;
  const totalRounds = gameOdds?.odds ? getTotalRounds(gameOdds?.odds) : null;
  const gameDate = game ? formatGameDate(game.start_date) : null;
  const gameTime = game ? formatToLocaleTimeString(game.start_date) : null;
  const gameDate2 = game ? formatFullDateWithOrdinalSuffix(game.start_date) : null;

  return (
    <div className={`w-full h-screen flex flex-row overflow-hidden ${darkMode ? 'bg-neutral-900' : 'bg-neutral-50'}`}>
      <Sidebar darkMode={darkMode}/>
      {data ? (
        <div className="flex flex-col w-full overflow-x-hidden">
          <div className={`sticky top-0 z-10 ${darkMode ? 'bg-neutral-900' : 'bg-white'}`}>
            <Navbar title="Sports" darkMode={darkMode} />
          </div>
          <div className="sticky top-24 z-9 bg-white propbets-page-tricker-div">
            <TrendingTicker league={game?.league}/>
          </div>
          <div className="mt-12 sm:mt-4 lg:mt-10 lg:ml-10 flex flex-col lg:flex-row w-full justify-center items-center lg:items-start gap-3">
            <div className="flex flex-col w-11/12 xs:w-9/12 md:w-1/2 lg:w-1/4">
              <div className={`z-8 w-full h-32 flex flex-col items-center justify-center ${darkMode ? 'bg-neutral-800' : 'bg-white'} p-4 mb-5 drop-shadow-sm`}>
                <div className="w-full flex justify-center items-center drop-shadow-md">
                  <span className={`text-xs font-normal mb-2 ${darkMode ? 'text-neutral-400' : 'text-neutral-500'}`}>
                    {game.season_year} {game.league} {game.season_type}
                  </span>
                </div>
                <div className="flex flex-row w-full justify-between items-center">
                  <TeamLink
                    team={awayTeam}
                    logo={awayTeam.logo}
                    abbreviation={awayTeam.team_abbreviation}
                    record={GameRecord(awayTeam)}
                    darkMode={darkMode}
                  />
                  <GameInfo game={game} gameDate={gameDate} gameTime={gameTime} liveGame={liveGame} darkMode={darkMode} />
                  <TeamLink
                    team={homeTeam}
                    logo={homeTeam.logo}
                    abbreviation={homeTeam.team_abbreviation}
                    record={GameRecord(homeTeam)}
                    darkMode={darkMode}
                  />
                </div>
              </div>

              <div className={`w-full h-44 ${darkMode ? 'bg-neutral-800' : 'bg-white'} flex flex-col ${game.status === 'completed' ? 'hidden' : ''}`}>
                <span className={`h-10 px-6 ${darkMode ? 'bg-neutral-700' : 'bg-neutral-900'} rounded-t-lg flex items-center text-white text-sm xl:text-base font-semibold uppercase`}>
                  {gameDate2}
                </span>
                <div className="w-full h-28 flex flex-col pt-4">
                  <div className="w-full h-20 flex flex-row items-center">
                    <div className="w-1/5 xs:w-1/6 h-20 flex flex-col items-center justify-center text-justify text-zinc-500 text-sm xl:text-base font-normal">
                      <span>{gameTime}</span>
                    </div>
                    <div className="w-5/6 h-20 flex flex-col justify-between items-end pr-2">
                      <div className="flex flex-row items-center justify-around w-full">
                        <span className={`text-sm xl:text-base mr-7 font-medium ${darkMode ? 'text-white' : 'text-indigo-950'}`}>
                          {homeTeam.team_abbreviation}
                        </span>
                        <div className="flex flex-row items-center gap-4 lg:gap-6 xl:gap-8 2xl:gap-10">
                          <div className="flex items-center gap-3">
                            <span className={`text-sm xl:text-base font-normal ${darkMode ? 'text-neutral-400' : 'text-zinc-500'}`}>
                              {matchingSpreads?.homeOdds?.selection_points}
                            </span>
                            <span className={`text-sm xl:text-base font-normal ${darkMode ? 'text-neutral-500' : 'text-zinc-400'}`}>
                              {matchingSpreads?.homeOdds?.price}
                            </span>
                          </div>
                          <div className="flex items-center gap-3">
                            <span className={`text-sm xl:text-base font-normal whitespace-nowrap ${darkMode ? 'text-neutral-400' : 'text-zinc-500'}`}>
                              {homeTotal?.selection_points
                                ? `U  ${homeTotal?.selection_points}`
                                : totalRounds?.selection_points}
                            </span>
                            <span className={`text-sm xl:text-base font-normal font-['Noto Sans'] ${darkMode ? 'text-neutral-500' : 'text-zinc-400'}`}>
                              {homeTotal?.price
                                ? homeTotal?.price
                                : totalRounds?.price
                                  ? totalRounds?.price
                                  : homeMoneyline?.price}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="w-full h-px bg-slate-200 rounded-lg"></div>
                      <div className="flex flex-row items-center justify-around w-full">
                        <span className={`text-sm xl:text-base mr-7 font-medium ${darkMode ? 'text-white' : 'text-indigo-950'}`}>
                          {awayTeam.team_abbreviation}
                        </span>
                        <div className="flex flex-row items-center gap-4 lg:gap-6 xl:gap-8 2xl:gap-10">
                          <div className="flex items-center gap-3">
                            <span className={`text-sm xl:text-base font-normal ${darkMode ? 'text-neutral-400' : 'text-zinc-500'}`}>
                              {matchingSpreads?.awayOdds?.selection_points}
                            </span>
                            <span className={`text-sm xl:text-base font-normal ${darkMode ? 'text-neutral-500' : 'text-zinc-400'}`}>
                              {matchingSpreads?.awayOdds?.price}
                            </span>
                          </div>
                          <div className="flex items-center gap-3">
                            <span className={`text-sm xl:text-base font-normal whitespace-nowrap ${darkMode ? 'text-neutral-400' : 'text-zinc-500'}`}>
                              {awayTotal?.selection_points
                                ? `O  ${awayTotal?.selection_points}`
                                : totalRounds?.selection_points}
                            </span>
                            <span className={`text-sm xl:text-base font-normal font-['Noto Sans'] ${darkMode ? 'text-neutral-500' : 'text-zinc-400'}`}>
                              {awayTotal?.price
                                ? awayTotal?.price
                                : totalRounds?.price
                                  ? totalRounds?.price
                                  : awayMoneyline?.price}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="w-full h-px bg-slate-200 rounded-lg"></div>
                    </div>
                  </div>
                  <div className="w-full h-10 flex items-center justify-between mt-3">
                    <span className={`text-sm xl:text-base font-normal capitalize px-4 ${darkMode ? 'text-white' : 'text-neutral-900'}`}>
                      matchup
                    </span>
                    <span className={`text-sm xl:text-base font-normal capitalize px-4 ${darkMode ? 'text-white' : 'text-neutral-900'}`}>
                      Line history
                    </span>
                  </div>
                </div>
              </div>

              {game?.status === 'completed' && liveGame ? (
                <BoxScoresTable boxScores={liveGame} darkMode={darkMode} />
              ) : null}
            </div>
            <div className="lg:ml-10 w-11/12 lg:w-2/3 mb-24 sm:mb-0">
              {game && game.status === 'completed' && game.status !== 'unplayed' && game.status !== 'live' ? (
                <PlayerBoxScores
                  playersData={playersData}
                  homeTeam={homeTeam}
                  awayTeam={awayTeam}
                  data={data}
                  darkMode={darkMode}
                />
              ) : (
                <PropBetsTable
                  data={data}
                  playersData={playersData}
                  setTeamSelection={setTeamSelection}
                  isSubscribed={isSubscribed}
                  loading={loading}
                  handleUserAccessForData={handleUserAccessForData}
                  darkMode={darkMode}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className={`content h-screen w-screen flex items-center justify-center ${darkMode ? 'bg-neutral-900' : 'bg-white'} animate-bg`}>
          <Loading />
        </div>
      )}
    </div>
  );
};
