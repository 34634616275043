// import React, { useEffect, useMemo, useState } from 'react';
// import '../styles/mobile/trendingTickerMobile.css';
// import { getUpcomingMatchups } from '../redux/actions/SportsActions';
// import { useDispatch, useSelector } from 'react-redux';
// import moment from 'moment';
// import { formatDateToMonthDay } from '../utils/dateUtils';
// import MatchupCard from './MatchupCard';

// const categoryToSportLeagueMap = {
//   NBA: { sport: 'basketball', league: 'NBA' },
//   NFL: { sport: 'football', league: 'NFL' },
//   NHL: { sport: 'hockey', league: 'NHL' },
//   MLB: { sport: 'baseball', league: 'MLB' },
//   UFC: { sport: 'mma', league: 'UFC' },
//   PGA: { sport: 'golf', league: 'PGA' },
//   WNBA: { sport: 'basketball', league: 'WNBA' },
//   'League of Legends': { sport: 'esports', league: 'League of Legends' },
//   Valorant: { sport: 'esports', league: 'Valorant' },
//   CS2: { sport: 'esports', league: 'CS2' },
// };

// const today = moment();
// const yesterday = moment().subtract(1, 'days');
// const tomorrow = moment().add(1, 'days');

// const toValueFormat = date => moment(date).format('YYYY-MM-DD');

// const dateOptions = [
//   { value: toValueFormat(yesterday), label: formatDateToMonthDay(yesterday) },
//   { value: toValueFormat(today), label: formatDateToMonthDay(today) },
//   { value: toValueFormat(tomorrow), label: formatDateToMonthDay(tomorrow) },
// ];

// const oddsOptions = {
//   default: [
//     { value: 'money_line', label: 'Moneyline' },
//     { value: 'point_spread', label: 'Spread' },
//     { value: 'total_points', label: 'Total' },
//   ],
//   mma: [
//     { value: 'money_line', label: 'Moneyline' },
//     { value: 'method_of_victory', label: 'Decision' },
//     { value: 'total_rounds', label: 'Total Rounds' },
//   ],
// };

// export const TrendingTicker = ({ darkMode, onSelectSport, league, selectedSport }) => {
//   const [selectedLeague, setSelectedLeague] = useState(selectedSport || 'All');
//   const [selectedDate, setSelectedDate] = useState(toValueFormat(today));
//   const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
//   const [isDateDropdownOpen, setIsDateDropdownOpen] = useState(false);
//   const [selectedOddsType, setSelectedOddsType] = useState('money_line');
//   const [isOddsDropdownOpen, setIsOddsDropdownOpen] = useState(false);
//   const matchupsFromStore = useSelector(state => state.getUpcomingMatchups.matchups);
//   const dispatch = useDispatch();

//   useEffect(() => {
//     Object.values(categoryToSportLeagueMap).forEach(sportLeague => {
//       dispatch(getUpcomingMatchups(sportLeague.sport, sportLeague.league));
//     });
//   }, [dispatch, selectedDate]);

//   useEffect(() => {
//     if (selectedSport) {
//       setSelectedLeague(selectedSport);
//     }
//   }, [selectedSport]);

//   const filteredMatchups = useMemo(() => {
//     let allFilteredMatchups = [];
//     Object.keys(matchupsFromStore).forEach(category => {
//       const filtered = matchupsFromStore[category].filter(matchup => {
//         const gameDate = moment.utc(matchup.start_date).local().format('YYYY-MM-DD');
//         return gameDate === selectedDate;
//       });
//       allFilteredMatchups = [...allFilteredMatchups, ...filtered];
//     });
//     if (selectedLeague === 'esports') {
//       allFilteredMatchups = allFilteredMatchups.filter(matchup =>
//         ['League of Legends', 'Valorant', 'CS2'].includes(matchup.league)
//       );
//     } else if (selectedLeague !== 'All') {
//       allFilteredMatchups = allFilteredMatchups.filter(matchup => matchup.league === selectedLeague);
//     }
//     return allFilteredMatchups;
//   }, [matchupsFromStore, selectedDate, selectedLeague]);

//   const groupedMatchups = useMemo(() => {
//     const groups = {};
//     filteredMatchups.forEach(matchup => {
//       const league = matchup.league;
//       if (!groups[league]) {
//         groups[league] = [];
//       }
//       groups[league].push(matchup);
//     });
//     return groups;
//   }, [filteredMatchups]);

//   const handleCategoryDropdown = () => {
//     setIsCategoryDropdownOpen(!isCategoryDropdownOpen);
//     setIsDateDropdownOpen(false);
//     setIsOddsDropdownOpen(false);
//   };

//   const handleDateDropdown = () => {
//     setIsDateDropdownOpen(!isDateDropdownOpen);
//     setIsCategoryDropdownOpen(false);
//     setIsOddsDropdownOpen(false);
//   };

//   const handleOddsDropdown = () => {
//     setIsOddsDropdownOpen(!isOddsDropdownOpen);
//     setIsCategoryDropdownOpen(false);
//     setIsDateDropdownOpen(false);
//   };

//   const handleOddsChange = oddsType => {
//     setSelectedOddsType(oddsType);
//     setIsOddsDropdownOpen(false);
//   };

//   const handleCategoryChange = category => {
//     setSelectedLeague(category);
//     onSelectSport(category);
//     if (category === 'UFC') {
//       setSelectedOddsType('money_line');
//     }
//     setIsCategoryDropdownOpen(false);
//   };

//   const options = [
//     { value: 'All', label: 'All' },
//     ...Object.keys(categoryToSportLeagueMap)
//       .filter(key => !['League of Legends', 'Valorant', 'CS2'].includes(key))
//       .map(key => ({ value: key, label: key })),
//     { value: 'esports', label: 'Esports' },
//   ];

//   const currentOddsOptions = selectedLeague === 'UFC' ? oddsOptions.mma : oddsOptions.default;

//   return (
//     <div className={`w-full rounded-lg p-2.5 flex flex-row ${darkMode ? 'bg-gray-910 text-white' : 'bg-white text-black'}`} style={{ zIndex: 20 }}>
//       <div className="flex flex-col gap-3">
//         <div className="flex flex-row gap-3">
//           {/* Sports Dropdown */}
//           <div className="dropdown relative inline-block">
//             <button
//               onClick={handleCategoryDropdown}
//               className="bg-gradient-to-r from-black to-black border-2 rounded-full text-white font-semibold py-2 px-4 inline-flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 shadow-lg">
//               <span className="mr-1">{selectedLeague}</span>
//               <svg
//                 className="fill-current h-4 w-4 transform transition-transform duration-200"
//                 style={{ transform: isCategoryDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
//                 xmlns="http://www.w3.org/2000/svg"
//                 viewBox="0 0 20 20"
//               >
//                 <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
//               </svg>
//             </button>
//             <ul
//               className={`dropdown-menu absolute z-10 w-full mt-1 bg-white shadow-lg rounded-md py-1 ring-1 ring-black ring-opacity-5 focus:outline-none transition ease-in-out duration-150 ${isCategoryDropdownOpen ? 'block' : 'hidden'}`}
//             >
//               {options.map((option, index) => (
//                 <li key={index} className="cursor-pointer">
//                   <span
//                     onClick={() => handleCategoryChange(option.value)}
//                     className="block px-4 py-2 text-sm text-gray-700 hover:bg-primary"
//                   >
//                     {option.label}
//                   </span>
//                 </li>
//               ))}
//             </ul>
//           </div>

//           {/* Date Dropdown */}
//           <div className="dropdown relative inline-block">
//             <button
//               onClick={handleDateDropdown}
//               className="bg-gradient-to-r from-black to-black border-2 rounded-full text-white font-semibold py-2 px-4 inline-flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 shadow-lg"
//             >
//               <span className="mr-1 w-12 text-sm">{dateOptions.find(option => option.value === selectedDate)?.label}</span>
//               <svg
//                 className="fill-current h-4 w-4 transform transition-transform duration-200"
//                 style={{ transform: isDateDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
//                 xmlns="http://www.w3.org/2000/svg"
//                 viewBox="0 0 20 20"
//               >
//                 <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
//               </svg>
//             </button>
//             <ul
//               className={`dropdown-menu absolute z-30 w-full mt-1 bg-white shadow-lg rounded-md py-1 ring-1 ring-black ring-opacity-5 focus:outline-none transition ease-in-out duration-150 ${isDateDropdownOpen ? 'block' : 'hidden'}`}
//               style={{ zIndex: 80 }}
//             >
//               {dateOptions.map((option, index) => (
//                 <li key={index} className="cursor-pointer hover:bg-primary z-60">
//                   <button
//                     onClick={() => {
//                       setSelectedDate(option.value);
//                       setIsDateDropdownOpen(false);
//                     }}
//                     className="block px-4 py-2 text-sm text-gray-700 "
//                   >
//                     {option.label}
//                   </button>
//                 </li>
//               ))}
//             </ul>
//           </div>
//         </div>

//         {/* Odds Dropdown */}
//         <div className="dropdown relative inline-block ml-10 z-10">
//           <button
//             onClick={handleOddsDropdown}
//             className="bg-gradient-to-r from-black to-black border-2 rounded-full text-white font-semibold py-2 px-4 inline-flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 shadow-lg"
//           >
//             <span className="mr-1">{currentOddsOptions.find(option => option.value === selectedOddsType)?.label}</span>
//             <svg
//               className="fill-current h-4 w-4 ml-1 transform transition-transform duration-200"
//               style={{ transform: isOddsDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
//               xmlns="http://www.w3.org/2000/svg"
//               viewBox="0 0 20 20"
//             >
//               <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
//             </svg>
//           </button>
//           <ul
//             className={`absolute z-10 w-full mt-1 bg-white shadow-lg rounded-md py-1 ring-1 ring-black ring-opacity-5 focus:outline-none transition ease-in-out duration-150 ${isOddsDropdownOpen ? 'block' : 'hidden'}`}
//           >
//             {currentOddsOptions.map((option, index) => (
//               <li key={index} className="cursor-pointer hover:bg-primary">
//                 <button
//                   onClick={() => handleOddsChange(option.value)}
//                   className="block px-4 py-2 text-sm text-gray-700"
//                 >
//                   {option.label}
//                 </button>
//               </li>
//             ))}
//           </ul>
//         </div>
//       </div>
      
//       {/* Matchups container */}
//       <div className="matchups-container overflow-x-auto flex flex-nowrap w-full">
//         {Object.keys(groupedMatchups).length === 0 && selectedLeague !== 'All' && (
//           <div className="flex flex-row items-center justify-center w-full">
//             <span className={`text-lg font-bold ${darkMode ? 'bg-gray-910 text-white' : 'bg-white text-black'}`}>No matchups for this day</span>
//           </div>
//         )}
//         {Object.keys(groupedMatchups).map((league, index) => (
//           <div key={index} className="flex flex-row items-center">
//             <div className="league-title-bg rounded-md px-3 py-1 text-lg font-bold text-gray-700 mr-4">{league}</div>
//             {groupedMatchups[league].map((matchup, index) => (
//               <MatchupCard key={index} matchup={matchup} selectedOddsType={selectedOddsType} />
//             ))}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default TrendingTicker;


// perfect ticker and this is before defaulting CS2 to esports
// import React, { useEffect, useMemo, useState } from 'react';
// import '../styles/mobile/trendingTickerMobile.css';
// import { getUpcomingMatchups } from '../redux/actions/SportsActions';
// import { useDispatch, useSelector } from 'react-redux';
// import moment from 'moment';
// import { formatDateToMonthDay } from '../utils/dateUtils';
// import MatchupCard from './MatchupCard';

// const categoryToSportLeagueMap = {
//   NBA: { sport: 'basketball', league: 'NBA' },
//   NFL: { sport: 'football', league: 'NFL' },
//   NHL: { sport: 'hockey', league: 'NHL' },
//   MLB: { sport: 'baseball', league: 'MLB' },
//   UFC: { sport: 'mma', league: 'UFC' },
//   PGA: { sport: 'golf', league: 'PGA' },
//   WNBA: { sport: 'basketball', league: 'WNBA' },
//   'League of Legends': { sport: 'esports', league: 'League of Legends' },
//   Valorant: { sport: 'esports', league: 'Valorant' },
//   CS2: { sport: 'esports', league: 'CS2' },
// };

// const leagueDisplayNameMap = {
//   'League of Legends': 'LoL',
// };

// const today = moment();
// const yesterday = moment().subtract(1, 'days');
// const tomorrow = moment().add(1, 'days');

// const toValueFormat = date => moment(date).format('YYYY-MM-DD');

// const dateOptions = [
//   { value: toValueFormat(yesterday), label: formatDateToMonthDay(yesterday) },
//   { value: toValueFormat(today), label: formatDateToMonthDay(today) },
//   { value: toValueFormat(tomorrow), label: formatDateToMonthDay(tomorrow) },
// ];

// const oddsOptions = {
//   default: [
//     { value: 'money_line', label: 'Moneyline' },
//     { value: 'point_spread', label: 'Spread' },
//     { value: 'total_points', label: 'Total' },
//   ],
//   mma: [
//     { value: 'money_line', label: 'Moneyline' },
//     { value: 'method_of_victory', label: 'Decision' },
//     { value: 'total_rounds', label: 'Total Rounds' },
//   ],
// };

// export const TrendingTicker = ({ darkMode, onSelectSport, league, selectedSport }) => {
//   const [selectedLeague, setSelectedLeague] = useState(selectedSport || 'All');
//   const [selectedDate, setSelectedDate] = useState(toValueFormat(today));
//   const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
//   const [isDateDropdownOpen, setIsDateDropdownOpen] = useState(false);
//   const [selectedOddsType, setSelectedOddsType] = useState('money_line');
//   const [isOddsDropdownOpen, setIsOddsDropdownOpen] = useState(false);
//   const matchupsFromStore = useSelector(state => state.getUpcomingMatchups.matchups);
//   const dispatch = useDispatch();

//   useEffect(() => {
//     Object.values(categoryToSportLeagueMap).forEach(sportLeague => {
//       dispatch(getUpcomingMatchups(sportLeague.sport, sportLeague.league));
//     });
//   }, [dispatch, selectedDate]);

//   useEffect(() => {
//     if (selectedSport) {
//       setSelectedLeague(selectedSport);
//     }
//   }, [selectedSport]);

//   const filteredMatchups = useMemo(() => {
//     let allFilteredMatchups = [];
//     Object.keys(matchupsFromStore).forEach(category => {
//       const filtered = matchupsFromStore[category].filter(matchup => {
//         const gameDate = moment.utc(matchup.start_date).local().format('YYYY-MM-DD');
//         return gameDate === selectedDate;
//       });
//       allFilteredMatchups = [...allFilteredMatchups, ...filtered];
//     });
//     if (selectedLeague === 'esports') {
//       allFilteredMatchups = allFilteredMatchups.filter(matchup =>
//         ['League of Legends', 'Valorant', 'CS2'].includes(matchup.league)
//       );
//     } else if (selectedLeague !== 'All') {
//       allFilteredMatchups = allFilteredMatchups.filter(matchup => matchup.league === selectedLeague);
//     }
//     return allFilteredMatchups;
//   }, [matchupsFromStore, selectedDate, selectedLeague]);

//   const groupedMatchups = useMemo(() => {
//     const groups = {};
//     filteredMatchups.forEach(matchup => {
//       const league = matchup.league;
//       if (!groups[league]) {
//         groups[league] = [];
//       }
//       groups[league].push(matchup);
//     });
//     return groups;
//   }, [filteredMatchups]);

//   const handleCategoryDropdown = () => {
//     setIsCategoryDropdownOpen(!isCategoryDropdownOpen);
//     setIsDateDropdownOpen(false);
//     setIsOddsDropdownOpen(false);
//   };

//   const handleDateDropdown = () => {
//     setIsDateDropdownOpen(!isDateDropdownOpen);
//     setIsCategoryDropdownOpen(false);
//     setIsOddsDropdownOpen(false);
//   };

//   const handleOddsDropdown = () => {
//     setIsOddsDropdownOpen(!isOddsDropdownOpen);
//     setIsCategoryDropdownOpen(false);
//     setIsDateDropdownOpen(false);
//   };

//   const handleOddsChange = oddsType => {
//     setSelectedOddsType(oddsType);
//     setIsOddsDropdownOpen(false);
//   };

//   const handleCategoryChange = category => {
//     setSelectedLeague(category);
//     onSelectSport(category);
//     if (category === 'UFC') {
//       setSelectedOddsType('money_line');
//     }
//     setIsCategoryDropdownOpen(false);
//   };

//   const options = [
//     { value: 'All', label: 'All' },
//     ...Object.keys(categoryToSportLeagueMap)
//       .filter(key => !['League of Legends', 'Valorant', 'CS2'].includes(key))
//       .map(key => ({ value: key, label: key })),
//     { value: 'esports', label: 'Esports' },
//   ];

//   const currentOddsOptions = selectedLeague === 'UFC' ? oddsOptions.mma : oddsOptions.default;

//   return (
//     <div className={`w-full rounded-lg p-2.5 flex flex-row ${darkMode ? 'bg-gray-910 text-white' : 'bg-white text-black'}`} style={{ zIndex: 20 }}>
//       <div className="flex flex-col gap-3">
//         <div className="flex flex-row gap-3">
//           {/* Sports Dropdown */}
//           <div className="dropdown relative inline-block">
//             <button
//               onClick={handleCategoryDropdown}
//               className="bg-gradient-to-r from-black to-black border-2 rounded-full text-white font-semibold py-2 px-4 inline-flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 shadow-lg">
//               <span className="mr-1">{selectedLeague}</span>
//               <svg
//                 className="fill-current h-4 w-4 transform transition-transform duration-200"
//                 style={{ transform: isCategoryDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
//                 xmlns="http://www.w3.org/2000/svg"
//                 viewBox="0 0 20 20"
//               >
//                 <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
//               </svg>
//             </button>
//             <ul
//               className={`dropdown-menu absolute z-10 w-full mt-1 bg-white shadow-lg rounded-md py-1 ring-1 ring-black ring-opacity-5 focus:outline-none transition ease-in-out duration-150 ${isCategoryDropdownOpen ? 'block' : 'hidden'}`}
//             >
//               {options.map((option, index) => (
//                 <li key={index} className="cursor-pointer">
//                   <span
//                     onClick={() => handleCategoryChange(option.value)}
//                     className="block px-4 py-2 text-sm text-gray-700 hover:bg-primary"
//                   >
//                     {option.label}
//                   </span>
//                 </li>
//               ))}
//             </ul>
//           </div>

//           {/* Date Dropdown */}
//           <div className="dropdown relative inline-block">
//             <button
//               onClick={handleDateDropdown}
//               className="bg-gradient-to-r from-black to-black border-2 rounded-full text-white font-semibold py-2 px-4 inline-flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 shadow-lg"
//             >
//               <span className="mr-1 w-12 text-sm">{dateOptions.find(option => option.value === selectedDate)?.label}</span>
//               <svg
//                 className="fill-current h-4 w-4 transform transition-transform duration-200"
//                 style={{ transform: isDateDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
//                 xmlns="http://www.w3.org/2000/svg"
//                 viewBox="0 0 20 20"
//               >
//                 <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
//               </svg>
//             </button>
//             <ul
//               className={`dropdown-menu absolute z-30 w-full mt-1 bg-white shadow-lg rounded-md py-1 ring-1 ring-black ring-opacity-5 focus:outline-none transition ease-in-out duration-150 ${isDateDropdownOpen ? 'block' : 'hidden'}`}
//               style={{ zIndex: 80 }}
//             >
//               {dateOptions.map((option, index) => (
//                 <li key={index} className="cursor-pointer hover:bg-primary z-60">
//                   <button
//                     onClick={() => {
//                       setSelectedDate(option.value);
//                       setIsDateDropdownOpen(false);
//                     }}
//                     className="block px-4 py-2 text-sm text-gray-700 "
//                   >
//                     {option.label}
//                   </button>
//                 </li>
//               ))}
//             </ul>
//           </div>
//         </div>

//         {/* Odds Dropdown */}
//         <div className="dropdown relative inline-block ml-10 z-10">
//           <button
//             onClick={handleOddsDropdown}
//             className="bg-gradient-to-r from-black to-black border-2 rounded-full text-white font-semibold py-2 px-4 inline-flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 shadow-lg"
//           >
//             <span className="mr-1">{currentOddsOptions.find(option => option.value === selectedOddsType)?.label}</span>
//             <svg
//               className="fill-current h-4 w-4 ml-1 transform transition-transform duration-200"
//               style={{ transform: isOddsDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
//               xmlns="http://www.w3.org/2000/svg"
//               viewBox="0 0 20 20"
//             >
//               <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
//             </svg>
//           </button>
//           <ul
//             className={`absolute z-10 w-full mt-1 bg-white shadow-lg rounded-md py-1 ring-1 ring-black ring-opacity-5 focus:outline-none transition ease-in-out duration-150 ${isOddsDropdownOpen ? 'block' : 'hidden'}`}
//           >
//             {currentOddsOptions.map((option, index) => (
//               <li key={index} className="cursor-pointer hover:bg-primary">
//                 <button
//                   onClick={() => handleOddsChange(option.value)}
//                   className="block px-4 py-2 text-sm text-gray-700"
//                 >
//                   {option.label}
//                 </button>
//               </li>
//             ))}
//           </ul>
//         </div>
//       </div>
      
//       {/* Matchups container */}
//       <div className="matchups-container overflow-x-auto flex flex-nowrap w-full">
//         {Object.keys(groupedMatchups).length === 0 && selectedLeague !== 'All' && (
//           <div className="flex flex-row items-center justify-center w-full">
//             <span className={`text-lg font-bold ${darkMode ? 'bg-gray-910 text-white' : 'bg-white text-black'}`}>No matchups for this day</span>
//           </div>
//         )}
//         {Object.keys(groupedMatchups).map((league, index) => (
//           <div key={index} className="flex flex-row items-center">
//             <div className="league-title-bg rounded-md px-3 py-1 text-lg font-bold text-gray-700 mr-4">
//               {leagueDisplayNameMap[league] || league}
//             </div>
//             {groupedMatchups[league].map((matchup, index) => (
//               <MatchupCard key={index} matchup={matchup} selectedOddsType={selectedOddsType} />
//             ))}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default TrendingTicker;



import React, { useEffect, useMemo, useState } from 'react';
import '../styles/mobile/trendingTickerMobile.css';
import { getUpcomingMatchups } from '../redux/actions/SportsActions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { formatDateToMonthDay } from '../utils/dateUtils';
import MatchupCard from './MatchupCard';

const categoryToSportLeagueMap = {
  NBA: { sport: 'basketball', league: 'NBA' },
  NFL: { sport: 'football', league: 'NFL' },
  NHL: { sport: 'hockey', league: 'NHL' },
  MLB: { sport: 'baseball', league: 'MLB' },
  UFC: { sport: 'mma', league: 'UFC' },
  PGA: { sport: 'golf', league: 'PGA' },
  WNBA: { sport: 'basketball', league: 'WNBA' },
  'League of Legends': { sport: 'esports', league: 'League of Legends' },
  Valorant: { sport: 'esports', league: 'Valorant' },
  CS2: { sport: 'esports', league: 'CS2' },
};

const leagueDisplayNameMap = {
  'League of Legends': 'LoL',
};

const today = moment();
const yesterday = moment().subtract(1, 'days');
const tomorrow = moment().add(1, 'days');

const toValueFormat = date => moment(date).format('YYYY-MM-DD');

const dateOptions = [
  { value: toValueFormat(yesterday), label: formatDateToMonthDay(yesterday) },
  { value: toValueFormat(today), label: formatDateToMonthDay(today) },
  { value: toValueFormat(tomorrow), label: formatDateToMonthDay(tomorrow) },
];

const oddsOptions = {
  default: [
    { value: 'money_line', label: 'Moneyline' },
    { value: 'point_spread', label: 'Spread' },
    { value: 'total_points', label: 'Total' },
  ],
  mma: [
    { value: 'money_line', label: 'Moneyline' },
    { value: 'method_of_victory', label: 'Decision' },
    { value: 'total_rounds', label: 'Total Rounds' },
  ],
};

export const TrendingTicker = ({ darkMode, onSelectSport, league, selectedSport }) => {
  const [selectedLeague, setSelectedLeague] = useState(selectedSport || 'All');
  const [selectedDate, setSelectedDate] = useState(toValueFormat(today));
  const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
  const [isDateDropdownOpen, setIsDateDropdownOpen] = useState(false);
  const [selectedOddsType, setSelectedOddsType] = useState('money_line');
  const [isOddsDropdownOpen, setIsOddsDropdownOpen] = useState(false);
  const matchupsFromStore = useSelector(state => state.getUpcomingMatchups.matchups);
  const dispatch = useDispatch();

  useEffect(() => {
    Object.values(categoryToSportLeagueMap).forEach(sportLeague => {
      dispatch(getUpcomingMatchups(sportLeague.sport, sportLeague.league));
    });
  }, [dispatch, selectedDate]);

  useEffect(() => {
    if (selectedSport) {
      if (selectedSport === 'CS2') {
        setSelectedLeague('esports');
      } else {
        setSelectedLeague(selectedSport);
      }
    }
  }, [selectedSport]);

  const filteredMatchups = useMemo(() => {
    let allFilteredMatchups = [];
    Object.keys(matchupsFromStore).forEach(category => {
      const filtered = matchupsFromStore[category].filter(matchup => {
        const gameDate = moment.utc(matchup.start_date).local().format('YYYY-MM-DD');
        return gameDate === selectedDate;
      });
      allFilteredMatchups = [...allFilteredMatchups, ...filtered];
    });
    if (selectedLeague === 'esports') {
      allFilteredMatchups = allFilteredMatchups.filter(matchup =>
        ['League of Legends', 'Valorant', 'CS2'].includes(matchup.league)
      );
    } else if (selectedLeague !== 'All') {
      allFilteredMatchups = allFilteredMatchups.filter(matchup => matchup.league === selectedLeague);
    }
    return allFilteredMatchups;
  }, [matchupsFromStore, selectedDate, selectedLeague]);

  const groupedMatchups = useMemo(() => {
    const groups = {};
    filteredMatchups.forEach(matchup => {
      const league = matchup.league;
      if (!groups[league]) {
        groups[league] = [];
      }
      groups[league].push(matchup);
    });
    return groups;
  }, [filteredMatchups]);

  const handleCategoryDropdown = () => {
    setIsCategoryDropdownOpen(!isCategoryDropdownOpen);
    setIsDateDropdownOpen(false);
    setIsOddsDropdownOpen(false);
  };

  const handleDateDropdown = () => {
    setIsDateDropdownOpen(!isDateDropdownOpen);
    setIsCategoryDropdownOpen(false);
    setIsOddsDropdownOpen(false);
  };

  const handleOddsDropdown = () => {
    setIsOddsDropdownOpen(!isOddsDropdownOpen);
    setIsCategoryDropdownOpen(false);
    setIsDateDropdownOpen(false);
  };

  const handleOddsChange = oddsType => {
    setSelectedOddsType(oddsType);
    setIsOddsDropdownOpen(false);
  };

  const handleCategoryChange = category => {
    setSelectedLeague(category);
    onSelectSport(category);
    if (category === 'UFC') {
      setSelectedOddsType('money_line');
    }
    setIsCategoryDropdownOpen(false);
  };

  const options = [
    { value: 'All', label: 'All' },
    ...Object.keys(categoryToSportLeagueMap)
      .filter(key => !['League of Legends', 'Valorant', 'CS2'].includes(key))
      .map(key => ({ value: key, label: key })),
    { value: 'esports', label: 'Esports' },
  ];

  const currentOddsOptions = selectedLeague === 'UFC' ? oddsOptions.mma : oddsOptions.default;

  return (
    <div className={`w-full rounded-lg p-2.5 flex items-center flex-row ${darkMode ? 'bg-gray-910 text-white' : 'bg-white text-black'}`} style={{ zIndex: 20 }}>
      <div className="flex flex-col gap-1 sm:gap-3">
        <div className="flex flex-row gap-1 sm:gap-3">
          {/* Sports Dropdown */}
          <div className="dropdown relative inline-block">
            <button
              onClick={handleCategoryDropdown}
              className="bg-gradient-to-r from-black to-black border-2 rounded-full text-white font-semibold py-2 px-4 inline-flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 shadow-lg">
              <span className="mr-1">{selectedLeague}</span>
              <svg
                className="fill-current h-4 w-4 transform transition-transform duration-200"
                style={{ transform: isCategoryDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </button>
            <ul
              className={`dropdown-menu absolute z-10 w-full mt-1 bg-white shadow-lg rounded-md py-1 ring-1 ring-black ring-opacity-5 focus:outline-none transition ease-in-out duration-150 ${isCategoryDropdownOpen ? 'block' : 'hidden'}`}
            >
              {options.map((option, index) => (
                <li key={index} className="cursor-pointer">
                  <span
                    onClick={() => handleCategoryChange(option.value)}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-primary"
                  >
                    {option.label}
                  </span>
                </li>
              ))}
            </ul>
          </div>

          {/* Date Dropdown */}
          <div className="dropdown relative inline-block">
            <button
              onClick={handleDateDropdown}
              className="bg-gradient-to-r from-black to-black border-2 rounded-full text-white font-semibold py-2 px-4 inline-flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 shadow-lg"
            >
              <span className="mr-1 w-12 text-sm">{dateOptions.find(option => option.value === selectedDate)?.label}</span>
              <svg
                className="fill-current h-4 w-4 transform transition-transform duration-200"
                style={{ transform: isDateDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </button>
            <ul
              className={`dropdown-menu absolute z-30 w-full mt-1 bg-white shadow-lg rounded-md py-1 ring-1 ring-black ring-opacity-5 focus:outline-none transition ease-in-out duration-150 ${isDateDropdownOpen ? 'block' : 'hidden'}`}
              style={{ zIndex: 80 }}
            >
              {dateOptions.map((option, index) => (
                <li key={index} className="cursor-pointer hover:bg-primary z-60">
                  <button
                    onClick={() => {
                      setSelectedDate(option.value);
                      setIsDateDropdownOpen(false);
                    }}
                    className="block px-4 py-2 text-sm text-gray-700 "
                  >
                    {option.label}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Odds Dropdown */}
        <div className="dropdown relative inline-block ml-10 z-10">
          <button
            onClick={handleOddsDropdown}
            className="bg-gradient-to-r from-black to-black border-2 rounded-full text-white font-semibold py-2 px-4 inline-flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 shadow-lg"
          >
            <span className="mr-1">{currentOddsOptions.find(option => option.value === selectedOddsType)?.label}</span>
            <svg
              className="fill-current h-4 w-4 ml-1 transform transition-transform duration-200"
              style={{ transform: isOddsDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </button>
          <ul
            className={`absolute z-10 w-full mt-1 bg-white shadow-lg rounded-md py-1 ring-1 ring-black ring-opacity-5 focus:outline-none transition ease-in-out duration-150 ${isOddsDropdownOpen ? 'block' : 'hidden'}`}
          >
            {currentOddsOptions.map((option, index) => (
              <li key={index} className="cursor-pointer hover:bg-primary">
                <button
                  onClick={() => handleOddsChange(option.value)}
                  className="block px-4 py-2 text-sm text-gray-700"
                >
                  {option.label}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      
      {/* Matchups container */}
      <div className="matchups-container overflow-x-auto flex flex-nowrap w-full">
        {Object.keys(groupedMatchups).length === 0 && selectedLeague !== 'All' && (
          <div className="flex flex-row items-center justify-center w-full">
            <span className={`text-sm sm:text-md md:text-lg font-bold ${darkMode ? 'bg-gray-910 text-white' : 'bg-white text-black'}`}>No matchups for this day</span>
          </div>
        )}
        {Object.keys(groupedMatchups).map((league, index) => (
          <div key={index} className="flex flex-row items-center">
            <div className="league-title-bg rounded-md px-3 py-1 text-lg font-bold text-gray-700 mr-1 sm:mr-4">
              {leagueDisplayNameMap[league] || league}
            </div>
            {groupedMatchups[league].map((matchup, index) => (
              <MatchupCard key={index} matchup={matchup} selectedOddsType={selectedOddsType} />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrendingTicker;
