// import { Link } from 'react-router-dom';
// import { useEffect, useRef, useState } from 'react';
// import _ from 'lodash';
// import {
//   calculateStat,
//   determineOpponentTeam,
//   formatPlayerStats,
//   sortByProperty,
// } from '../../../utils/sportStats/playerStats';
// import { findOppositeCorrespondingOdds } from '../../../utils/sportStats/oddsFunctions';
// import { Loading } from '../../../components/LoadingComponent';
// import { images } from '../../../assets';

// export const PropBetsTable = ({
//   data,
//   playersData,
//   setTeamSelection,
//   isSubscribed,
//   loading,
//   handleUserAccessForData,
//   darkMode,
// }) => {
//   const [formattedStats, setFormattedStats] = useState([]);
//   const [processedPlayers, setProcessedPlayers] = useState([]);
//   const [isOn, setIsOn] = useState(true);
//   const [statMap, setStatMap] = useState([]);
//   const [selectedStat, setSelectedStat] = useState(null);
//   const [selectedButton, setSelectedButton] = useState(null);
//   const [statMapValue, setStatMapValue] = useState(null);
//   const initialSetRef = useRef(false);

//   useEffect(() => {
//     if (
//       data &&
//       data?.gameScore &&
//       (data?.gameOdds?.odds || data.gameScore.status === 'completed')
//     ) {
//       const newFormattedStats = formatPlayerStats(data?.gameScore?.sport);
//       setFormattedStats(newFormattedStats);

//       if (newFormattedStats.length > 0) {
//         const newStatMap = generateStatMap(newFormattedStats);
//         setStatMap(newStatMap);
        
//         const initialStatMapValue = newStatMap[0].value;
//         setSelectedStat(newStatMap[0].gameStat);
//         setSelectedButton(newStatMap[0].display);
//         setStatMapValue(newStatMap[0].value);
//         updateProcessedPlayers(initialStatMapValue);
//       }
//     } else {
//       setFormattedStats([]);
//       setProcessedPlayers([]);
//       setStatMap([]);
//       setSelectedStat(null);
//       setSelectedButton(null);
//       setStatMapValue(null);
//     }
//   }, [playersData, data.gameOdds?.odds, data.gameScore.status]);

//   useEffect(() => {
//     if (formattedStats.length > 0) {
//       updateProcessedPlayers(statMapValue);
//     }
//   }, [statMapValue]);

//   const updateProcessedPlayers = (selectedStatValue) => {
//     let newProcessedPlayers = playersData?.map((player) => {
//       let playerOdds = [];

//       if (data.gameScore.status !== 'completed') {
//         const groupedPlayerOdds = _.groupBy(data.gameOdds.odds, 'player_id');
//         playerOdds = groupedPlayerOdds[player?.playerDetails?.id] || [];
//       }

//       return {
//         ...player,
//         playerOdds: playerOdds,
//         hasOddsForSelectedStat: playerOdds.some(
//           (odd) => odd.market === selectedStatValue
//         ),
//       };
//     });

//     newProcessedPlayers = newProcessedPlayers.sort(
//       (a, b) => b.hasOddsForSelectedStat - a.hasOddsForSelectedStat
//     );

//     setProcessedPlayers(newProcessedPlayers);
//   };

//   const generateStatMap = (stats) => {
//     return stats.map((stat) => ({
//       key: stat?.stat,
//       value: stat?.value,
//       display: stat?.stat,
//       gameStat: stat?.gameStat,
//     }));
//   };

//   const handleButtonClick = (statKey, isClickable) => {
//     if (isSubscribed || isClickable) {
//       const statObj = statMap.find((stat) => stat.key === statKey);
//       setSelectedStat(statObj?.gameStat);
//       setSelectedButton(statObj?.display);
//       setStatMapValue(statObj?.value);
//     } else {
//       handleUserAccessForData();
//     }
//   };

//   const bgColor = (percentageString) => {
//     const percentageValue = parseFloat(percentageString);
//     return percentageValue >= 50 ? 'bg-green' : 'bg-red';
//   };

//   const renderStatButtons = () => (
//     <div className={` PlayerStats h-2/5 flex flex-row items-center justify-between overflow-x-auto whitespace-nowrap ${darkMode ? 'text-white' : 'text-black'}`}>
//       {formattedStats.map((stat, index) => {
//         const isClickable = isSubscribed || index === 0;
//         return (
//           <button
//             key={index}
//             onClick={() => handleButtonClick(stat.stat, isClickable)}
//             className={`h-9 p-3 mr-2 rounded-full flex items-center justify-center text-sm xs:text-base ${selectedButton === stat.stat ? 'bg-primary text-black font-bold' : 'text-slate-500'}`}
//           >
//             {stat.stat}
//           </button>
//         );
//       })}
//     </div>
//   );

//   const renderToggleSwitch = () => (
//     <div className="flex items-start gap-3 w-10/12 xs:w-1/3 my-8">
//       <span className={`text-slate-500 text-sm xs:text-base font-normal leading-normal ${darkMode ? 'text-white' : 'text-black'}`}>Away Team</span>
//       <label className="flex items-center cursor-pointer">
//         <div className="relative">
//           <input
//             type="checkbox"
//             id="toggleB"
//             className="sr-only"
//             checked={isOn}
//             onChange={() => {
//               setIsOn(!isOn);
//               setTeamSelection(isOn ? false : true);
//             }}
//           />
//           <div className={`block w-14 h-6 rounded-full ${darkMode ? 'bg-primary' : 'bg-neutral-900'}`}></div>
//           <div className={`dot absolute left-1 top-1 w-4 h-4 rounded-full duration-300 ease-in-out ${isOn ? 'transform translate-x-8' : ''} ${darkMode ? 'bg-black' : 'bg-primary'}`}></div>
//         </div>
//       </label>
//       <span className={`text-slate-500 text-sm xs:text-base font-normal leading-normal ${darkMode ? 'text-white' : 'text-black'}`}>Home team</span>
//     </div>
//   );

//   const renderProcessedPlayers = () => (
//     processedPlayers.map((player, index) => {
//       const playerTeamName = player?.playerDetails?.team_name;
//       const opponentTeam = determineOpponentTeam(playerTeamName, data.gameScore);
//       const mainOdd = player.playerOdds.find((odd) => odd.market === statMapValue && odd.is_main);

//       let line, under, over;
//       if (mainOdd) {
//         line = mainOdd?.selection_points;
//         if (mainOdd?.selection_line) {
//           const oddsResult = findOppositeCorrespondingOdds(player.playerOdds, mainOdd);
//           under = oddsResult.under;
//           over = oddsResult.over;
//         }
//       }

//       const sortedPlayersScores = sortByProperty(player.playerResults, 'start_date', 'desc');
//       const StatCalculated = {
//         2023: calculateStat(sortedPlayersScores, selectedStat, selectedButton, '2023', line, opponentTeam, playerTeamName),
//         L10: calculateStat(sortedPlayersScores, selectedStat, selectedButton, 'L10', line, opponentTeam, playerTeamName),
//         L5: calculateStat(sortedPlayersScores, selectedStat, selectedButton, 'L5', line, opponentTeam, playerTeamName),
//         vs: calculateStat(sortedPlayersScores, selectedStat, selectedButton, 'vs', line, opponentTeam, playerTeamName),
//         L20: calculateStat(sortedPlayersScores, selectedStat, selectedButton, 'L20', line, opponentTeam, playerTeamName),
//         All: calculateStat(sortedPlayersScores, selectedStat, selectedButton, 'All', line, opponentTeam, playerTeamName),
//       };

//       return (
//         <div className="flex flex-col h-24 w-full" key={index}>
//           <div className="flex flex-row justify-between h-1/2 w-11/12 xs:w-4/5 lg:w-1/2">
//             <div className="flex-col justify-start items-start inline-flex">
//               <Link to={`/sports/playerInfo/${player.playerDetails.id}`}>
//                 <button className="text-slate-500 text-sm sm:text-base font-normal leading-normal">
//                   {player.playerDetails?.player_name}
//                 </button>
//               </Link>
//               <div className="w-12 flex flex-row justify-between gap-1">
//                 <span className="text-slate-500 text-xs font-medium leading-none">
//                   {!isOn ? data.away_team_info?.team_abbreviation : data.home_team_info?.team_abbreviation}
//                 </span>
//                 <span className="text-slate-500 text-xs font-medium leading-none">|</span>
//                 <span className="text-slate-500 text-xs font-medium leading-none">{player.playerDetails?.position}</span>
//               </div>
//             </div>
//             {line && (
//               <div className="w-52 h-10 justify-around items-start inline-flex">
//                 <div className="flex flex-col items-center">
//                   <span className="text-slate-500 text-base font-bold leading-normal">{line}</span>
//                 </div>
//                 <div className="justify-between gap-5 items-start inline-flex">
//                   <div className="flex flex-col items-center">
//                     <span className="text-slate-500 text-base font-normal leading-normal">{over}</span>
//                     <span className="text-green text-xs font-medium leading-none">O</span>
//                   </div>
//                   <div className="flex flex-col items-center">
//                     <span className="text-slate-500 text-base font-normal leading-normal">{under}</span>
//                     <span className="text-red text-xs font-medium leading-none">U</span>
//                   </div>
//                 </div>
//               </div>
//             )}
//           </div>
//           <div className="w-full h-1/2 flex justify-start items-start gap-1.5 xs:gap-2.5">
//             <div className={`h-10 flex-grow flex items-center justify-center ${bgColor(StatCalculated['2023'])} rounded-lg`}>
//               <span className="text-white text-center text-xs xs:text-sm sm:text-base font-medium">2024: {StatCalculated['2023']}</span>
//             </div>
//             <div className={`h-10 flex-grow flex items-center justify-center ${bgColor(StatCalculated['L10'])} rounded-lg`}>
//               <span className="text-white text-center text-xs xs:text-sm sm:text-base font-medium">L10: {StatCalculated['L10']}</span>
//             </div>
//             <div className={`h-10 flex-grow flex items-center justify-center ${bgColor(StatCalculated['L5'])} rounded-lg`}>
//               <span className="text-white text-center text-xs xs:text-sm sm:text-base font-medium">L5: {StatCalculated['L5']}</span>
//             </div>
//             <div className={`h-10 flex-grow flex items-center justify-center ${bgColor(StatCalculated['vs'])} rounded-lg`}>
//               <span className="text-white text-center text-xs xs:text-sm sm:text-base font-medium">
//                 vs {isOn ? data?.away_team_info.team_abbreviation : data?.home_team_info.team_abbreviation}: {StatCalculated['vs']}
//               </span>
//             </div>
//             <div className={`h-10 flex-grow flex items-center justify-center ${bgColor(StatCalculated['L20'])} rounded-lg`}>
//               <span className="text-white text-center text-xs xs:text-sm sm:text-base font-medium">L20: {StatCalculated['L20']}</span>
//             </div>
//           </div>
//         </div>
//       );
//     })
//   );

//   return !loading && formattedStats.length ? (
//     <div className="flex flex-col w-full">
//       <div className="flex flex-col h-24 w-full justify-between">
//         {renderStatButtons()}
//         <div className="h-1/3 w-1/2 flex flex-row items-center justify-between">
//           <button className="h-9 p-1.5 bg-primary rounded-full flex items-center justify-center flex-grow">
//             <span className="text-black text-sm xs:text-base font-bold leading-normal mx-3">Under</span>
//           </button>
//           <div className="p-1.5 rounded-full flex items-center justify-center flex-grow">
//             <span className={`text-sm xs:text-base font-normal leading-normal ${darkMode ? 'text-white' : 'text-slate-500'}`}>|</span>
//           </div>
//           <button className="p-1.5 rounded-full flex items-center justify-center flex-grow">
//             <span className={`text-sm xs:text-base font-normal leading-normal ${darkMode ? 'text-white' : 'text-slate-500'}`}>All</span>
//           </button>
//           <button className="p-1.5 rounded-full flex items-center justify-center flex-grow">
//             <span className={`text-sm xs:text-base font-normal leading-normal ${darkMode ? 'text-white' : 'text-slate-500'}`}>Last 10</span>
//           </button>
//           <button className="p-1.5 rounded-full flex items-center justify-center flex-grow">
//             <span className={`text-sm xs:text-base font-normal leading-normal ${darkMode ? 'text-white' : 'text-slate-500'}`}>Last 5</span>
//           </button>
//           <button className="p-1.5 rounded-full flex items-center justify-center flex-grow">
//             <span className={`text-sm xs:text-base font-normal leading-normal ${darkMode ? 'text-white' : 'text-slate-500'}`}>vs. Opp</span>
//           </button>
//           <button className="p-1.5 rounded-full flex items-center justify-center flex-grow">
//             <span className={`text-slate-500 text-sm xs:text-base font-normal leading-normal ${darkMode ? 'text-white' : 'text-slate-500'}`}>Last 20</span>
//           </button>
//           <button className="p-1.5 rounded-full flex items-center justify-center flex-grow">
//             <span className={`text-sm xs:text-base font-normal leading-normal ${darkMode ? 'text-white' : 'text-slate-500'}`}>2024</span>
//           </button>
//         </div>
//       </div>
//       {renderToggleSwitch()}
//       <div className="flex justify-center items-center mb-3">
//         <p className={`text-xs ${darkMode ? 'text-white' : 'text-slate-500'}`}>
//           These percentages indicate how often the player has met or exceeded the betting line over different periods: the entire season, the last 10 games, the last 5 games, against specific opponents, and over the past 20 games. CLICK ON THE PLAYER FOR MORE IN-DEPTH INSIGHTS
//         </p>
//       </div>
//       <div className="flex flex-col w-full">
//         {processedPlayers && processedPlayers.length ? renderProcessedPlayers() : (
//           <div className="flex flex-col w-full h-screen items-center justify-center animate-bg">
//             <Loading />
//           </div>
//         )}
//         {!isSubscribed && (
//           <div className="flex flex-col w-full">
//             <img
//               className="object-cover w-full h-full"
//               src={images.propBetsPlaceholder}
//               alt="propBetsPlaceholder"
//               loading="lazy"
//             />
//           </div>
//         )}
//       </div>
//     </div>
//   ) : (
//     <div className="flex flex-col w-full h-screen items-center justify-center animate-bg">
//       <Loading />
//     </div>
//   );
// };




import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import {
  calculateStat,
  determineOpponentTeam,
  formatPlayerStats,
  sortByProperty,
} from '../../../utils/sportStats/playerStats';
import { findOppositeCorrespondingOdds } from '../../../utils/sportStats/oddsFunctions';
import { Loading } from '../../../components/LoadingComponent';
import { images } from '../../../assets';

export const PropBetsTable = ({
  data,
  playersData,
  setTeamSelection,
  isSubscribed,
  loading,
  handleUserAccessForData,
}) => {
  const [formattedStats, setFormattedStats] = useState([]);
  const [processedPlayers, setProcessedPlayers] = useState([]);
  const [isOn, setIsOn] = useState(true);
  const [statMap, setStatMap] = useState([]);
  const [selectedStat, setSelectedStat] = useState(null);
  const [selectedButton, setSelectedButton] = useState(null);
  const [statMapValue, setStatMapValue] = useState(null);
  const initialSetRef = useRef(false);

  useEffect(() => {
    if (
      data &&
      data?.gameScore &&
      (data?.gameOdds?.odds || data.gameScore.status === 'completed')
    ) {
      const newFormattedStats = formatPlayerStats(data?.gameScore?.sport);
      setFormattedStats(newFormattedStats);

      if (newFormattedStats.length > 0) {
        const newStatMap = generateStatMap(newFormattedStats);
        setStatMap(newStatMap);
        
        const initialStatMapValue = newStatMap[0].value;
        setSelectedStat(newStatMap[0].gameStat);
        setSelectedButton(newStatMap[0].display);
        setStatMapValue(newStatMap[0].value);
        updateProcessedPlayers(initialStatMapValue);
      }
    } else {
      setFormattedStats([]);
      setProcessedPlayers([]);
      setStatMap([]);
      setSelectedStat(null);
      setSelectedButton(null);
      setStatMapValue(null);
    }
  }, [playersData, data.gameOdds?.odds, data.gameScore.status]);

  useEffect(() => {
    if (formattedStats.length > 0) {
      updateProcessedPlayers(statMapValue);
    }
  }, [statMapValue]);

  const updateProcessedPlayers = (selectedStatValue) => {
    let newProcessedPlayers = playersData?.map((player) => {
      let playerOdds = [];

      if (data.gameScore.status !== 'completed') {
        const groupedPlayerOdds = _.groupBy(data.gameOdds.odds, 'player_id');
        playerOdds = groupedPlayerOdds[player?.playerDetails?.id] || [];
      }

      return {
        ...player,
        playerOdds: playerOdds,
        hasOddsForSelectedStat: playerOdds.some(
          (odd) => odd.market === selectedStatValue
        ),
      };
    });

    newProcessedPlayers = newProcessedPlayers.sort(
      (a, b) => b.hasOddsForSelectedStat - a.hasOddsForSelectedStat
    );

    setProcessedPlayers(newProcessedPlayers);
  };

  const generateStatMap = (stats) => {
    return stats.map((stat) => ({
      key: stat?.stat,
      value: stat?.value,
      display: stat?.stat,
      gameStat: stat?.gameStat,
    }));
  };

  const handleButtonClick = (statKey, isClickable) => {
    if (isSubscribed || isClickable) {
      const statObj = statMap.find((stat) => stat.key === statKey);
      setSelectedStat(statObj?.gameStat);
      setSelectedButton(statObj?.display);
      setStatMapValue(statObj?.value);
    } else {
      handleUserAccessForData();
    }
  };

  const bgColor = (percentageString) => {
    const percentageValue = parseFloat(percentageString);
    return percentageValue >= 50 ? 'bg-green' : 'bg-red';
  };

  const renderStatButtons = () => (
    <div className="PlayerStats h-2/5 flex flex-row items-center justify-between overflow-x-auto whitespace-nowrap">
      {formattedStats.map((stat, index) => {
        const isClickable = isSubscribed || index === 0;
        return (
          <button
            key={index}
            onClick={() => handleButtonClick(stat.stat, isClickable)}
            className={`h-9 p-3 mr-2 rounded-full flex items-center justify-center text-sm xs:text-base ${selectedButton === stat.stat ? 'bg-primary text-black font-bold' : 'text-slate-500'}`}
          >
            {stat.stat}
          </button>
        );
      })}
    </div>
  );

  const renderToggleSwitch = () => (
    <div className="flex items-start gap-3 w-10/12 xs:w-1/3 my-8">
      <span className="text-slate-500 text-sm xs:text-base font-normal leading-normal">Away Team</span>
      <label className="flex items-center cursor-pointer">
        <div className="relative">
          <input
            type="checkbox"
            id="toggleB"
            className="sr-only"
            checked={isOn}
            onChange={() => {
              setIsOn(!isOn);
              setTeamSelection(isOn ? false : true);
            }}
          />
          <div className="block bg-neutral-900 w-14 h-6 rounded-full"></div>
          <div className={`dot absolute left-1 top-1 bg-primary w-4 h-4 rounded-full duration-300 ease-in-out ${isOn ? 'transform translate-x-8' : ''}`}></div>
        </div>
      </label>
      <span className="text-slate-500 text-sm xs:text-base font-normal leading-normal">Home team</span>
    </div>
  );

  const renderProcessedPlayers = () => {
    let playersToRender = processedPlayers;
  
    // Filter players if the sport is baseball and the selected stat is Strikeouts
    if (data.gameScore.sport === 'baseball') {
      if (selectedButton === 'Strikeouts' || selectedButton === 'Earned Runs' || selectedButton === 'IP') {
        playersToRender = processedPlayers.filter(player => 
          ['SP', 'RP'].includes(player.playerDetails?.position)
        );
      } else if (selectedButton === 'Hits' || selectedButton === 'Runs'||selectedButton === 'Walks') {
        playersToRender = processedPlayers.filter(player => 
          !['SP', 'RP'].includes(player.playerDetails?.position)
        );
      }
    }

    // filter players for football
    if (data.gameScore.sport === 'football'){
      if (selectedButton === 'Pass Yards'
      || selectedButton === 'Pass TDs'
      || selectedButton === 'Pass Attempts'
      || selectedButton === 'Pass Completions'
      || selectedButton === 'Int'
    ){
        playersToRender = processedPlayers.filter(player =>
          ['QB'].includes(player.playerDetails?.position)
        );
      } else if (selectedButton === 'Rush Yards'
      || selectedButton === 'Rush Attempts'
      || selectedButton === 'Pass+Rush+Rec TDs'
      || selectedButton === 'Rush+Rec Yards'
      || selectedButton === 'Pass+Rush Yards'
    ){
        playersToRender = processedPlayers.filter(player =>
          ['QB','TE','RB','WR'].includes(player.playerDetails?.position)
        );
      } else if (selectedButton === 'FG Made' || selectedButton === 'Kicking Points'){
        playersToRender = processedPlayers.filter(player =>
          ['K'].includes(player.playerDetails?.position)
        );
      } else if (selectedButton === 'Receiving Yards' || selectedButton === 'Receptions'){
        playersToRender = processedPlayers.filter(player =>
          ['TE','RB','WR'].includes(player.playerDetails?.position)
        );
      } else if (selectedButton === 'Sacks' || selectedButton === 'Tackles+Ast'){
        playersToRender = processedPlayers.filter(player =>
          ['LB','CB','DE','S'].includes(player.playerDetails?.position)
        );
      }
    }
  
    return playersToRender.map((player, index) => {
      const playerTeamName = player?.playerDetails?.team_name;
      const opponentTeam = determineOpponentTeam(playerTeamName, data.gameScore);
      const mainOdd = player.playerOdds.find((odd) => odd.market === statMapValue && odd.is_main);
  
      let line, under, over;
      if (mainOdd) {
        line = mainOdd?.selection_points;
        if (mainOdd?.selection_line) {
          const oddsResult = findOppositeCorrespondingOdds(player.playerOdds, mainOdd);
          under = oddsResult.under;
          over = oddsResult.over;
        }
      }
  
      const sortedPlayersScores = sortByProperty(player.playerResults, 'start_date', 'desc');
      const StatCalculated = {
        2023: calculateStat(sortedPlayersScores, selectedStat, selectedButton, '2023', line, opponentTeam, playerTeamName),
        L10: calculateStat(sortedPlayersScores, selectedStat, selectedButton, 'L10', line, opponentTeam, playerTeamName),
        L5: calculateStat(sortedPlayersScores, selectedStat, selectedButton, 'L5', line, opponentTeam, playerTeamName),
        vs: calculateStat(sortedPlayersScores, selectedStat, selectedButton, 'vs', line, opponentTeam, playerTeamName),
        L20: calculateStat(sortedPlayersScores, selectedStat, selectedButton, 'L20', line, opponentTeam, playerTeamName),
        All: calculateStat(sortedPlayersScores, selectedStat, selectedButton, 'All', line, opponentTeam, playerTeamName),
      };
      return (
        <div className="flex flex-col h-24 w-full" key={index}>
          <div className="flex flex-row justify-between h-1/2 w-11/12 xs:w-4/5 lg:w-1/2">
            <div className="flex-col justify-start items-start inline-flex">
              <Link to={`/sports/playerInfo/${player.playerDetails.id}`}>
                <button className="text-slate-500 text-sm sm:text-base font-normal leading-normal">
                  {player.playerDetails?.player_name}
                </button>
              </Link>
              <div className="w-12 flex flex-row justify-between gap-1">
                <span className="text-slate-500 text-xs font-medium leading-none">
                  {!isOn ? data.away_team_info?.team_abbreviation : data.home_team_info?.team_abbreviation}
                </span>
                <span className="text-slate-500 text-xs font-medium leading-none">|</span>
                <span className="text-slate-500 text-xs font-medium leading-none">{player.playerDetails?.position}</span>
              </div>
            </div>
            {line && (
              <div className="w-52 h-10 justify-around items-start inline-flex">
                <div className="flex flex-col items-center">
                  <span className="text-slate-500 text-base font-bold leading-normal">{line}</span>
                </div>
                <div className="justify-between gap-5 items-start inline-flex">
                  <div className="flex flex-col items-center">
                    <span className="text-slate-500 text-base font-normal leading-normal">{over}</span>
                    <span className="text-green text-xs font-medium leading-none">O</span>
                  </div>
                  <div className="flex flex-col items-center">
                    <span className="text-slate-500 text-base font-normal leading-normal">{under}</span>
                    <span className="text-red text-xs font-medium leading-none">U</span>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="w-full h-1/2 flex justify-start items-start gap-1.5 xs:gap-2.5">
            <div className={`min-w-[60px] sm:min-w-[90px] h-10 flex-grow flex items-center justify-center ${bgColor(StatCalculated['2024'])} rounded-lg`}>
              <span className="text-white text-center text-xs xs:text-sm sm:text-base font-medium">2024: {StatCalculated['2024']}</span>
            </div>
            <div className={`min-w-[60px] sm:min-w-[90px] h-10 flex-grow flex items-center justify-center ${bgColor(StatCalculated['L10'])} rounded-lg`}>
              <span className="text-white text-center text-xs xs:text-sm sm:text-base font-medium">L10: {StatCalculated['L10']}</span>
            </div>
            <div className={`min-w-[60px] sm:min-w-[90px] h-10 flex-grow flex items-center justify-center ${bgColor(StatCalculated['L5'])} rounded-lg`}>
              <span className="text-white text-center text-xs xs:text-sm sm:text-base font-medium">L5: {StatCalculated['L5']}</span>
            </div>
            <div className={`min-w-[60px] sm:min-w-[90px] h-10 flex-grow flex items-center justify-center ${bgColor(StatCalculated['vs'])} rounded-lg`}>
              <span className="text-white text-center text-xs xs:text-sm sm:text-base font-medium">
                vs {isOn ? data?.away_team_info.team_abbreviation : data?.home_team_info.team_abbreviation}: {StatCalculated['vs']}
              </span>
            </div>
            <div className={`min-w-[60px] sm:min-w-[90px] h-10 flex-grow flex items-center justify-center ${bgColor(StatCalculated['L20'])} rounded-lg`}>
              <span className="text-white text-center text-xs xs:text-sm sm:text-base font-medium">L20: {StatCalculated['L20']}</span>
            </div>
          </div>
        </div>
      );
    });
  };

  return !loading && formattedStats.length ? (
    <div className="flex flex-col w-full">
      <div className="flex flex-col h-24 w-full justify-between">
        {renderStatButtons()}
        <div className="h-1/3 w-1/2 flex flex-row items-center justify-between">
          <button className="h-9 p-1.5 bg-primary rounded-full flex items-center justify-center flex-grow">
            <span className="text-black text-sm xs:text-base font-bold leading-normal mx-3">Under</span>
          </button>
          <div className="p-1.5 rounded-full flex items-center justify-center flex-grow">
            <span className="text-slate-500 text-sm xs:text-base font-normal leading-normal">|</span>
          </div>
          <button className="p-1.5 rounded-full flex items-center justify-center flex-grow">
            <span className="text-slate-500 text-sm xs:text-base font-normal leading-normal">All</span>
          </button>
          <button className="p-1.5 rounded-full flex items-center justify-center flex-grow">
            <span className="text-slate-500 text-sm xs:text-base font-normal leading-normal">Last 10</span>
          </button>
          <button className="p-1.5 rounded-full flex items-center justify-center flex-grow">
            <span className="text-slate-500 text-sm xs:text-base font-normal leading-normal">Last 5</span>
          </button>
          <button className="p-1.5 rounded-full flex items-center justify-center flex-grow">
            <span className="text-slate-500 text-sm xs:text-base font-normal leading-normal">vs. Opp</span>
          </button>
          <button className="p-1.5 rounded-full flex items-center justify-center flex-grow">
            <span className="text-slate-500 text-sm xs:text-base font-normal leading-normal">Last 20</span>
          </button>
          <button className="p-1.5 rounded-full flex items-center justify-center flex-grow">
            <span className="text-slate-500 text-sm xs:text-base font-normal leading-normal">2022</span>
          </button>
        </div>
      </div>
      {renderToggleSwitch()}
      <div className="flex justify-center items-center mb-3">
        <p className="text-xs text-slate-500">
          These percentages indicate how often the player has met or exceeded the betting line over different periods: the entire season, the last 10 games, the last 5 games, against specific opponents, and over the past 20 games. CLICK ON THE PLAYER FOR MORE IN-DEPTH INSIGHTS
        </p>
      </div>
      <div className="flex flex-col w-full">
        {processedPlayers && processedPlayers.length ? renderProcessedPlayers() : (
          <div className="flex flex-col w-full h-screen items-center justify-center animate-bg">
            <Loading />
          </div>
        )}
        {!isSubscribed && (
          <div className="flex flex-col w-full">
            <img
              className="object-cover w-full h-full"
              src={images.propBetsPlaceholder}
              alt="propBetsPlaceholder"
              loading="lazy"
            />
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className="flex flex-col w-full h-screen items-center justify-center animate-bg">
      <Loading />
    </div>
  );
};